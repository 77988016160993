import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBottleWater } from '@fortawesome/free-solid-svg-icons';
import { faUser, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import styles from './Header.module.scss';
import SearchForm from '../SearchForm/SearchForm';
import { useAuth } from "../../Context/AuthProvider";
import HamburgerToggle from '../Buttons-loaders-inputs/MenuToggle/HamburgerToggle';

const Header = () => {
  const { logout, estaAutenticado, jwt } = useAuth();
  const [userDetails, setUserDetails] = useState({ username: '', rol: '' });
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (estaAutenticado && jwt) {
      const storedUserDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
      if (storedUserDetails) {
        setUserDetails(JSON.parse(storedUserDetails));
      }
    }
  }, [estaAutenticado, jwt]);

  const handleLogout = () => {
    logout();
  };

  const toggleNavbar = () => setExpanded(!expanded);

  const formatUsername = (username) => {
    const spaceIndex = username.indexOf(' ');
    if (spaceIndex !== -1) {
      return username.split(' ')[0];
    } else if (username.length > 10) {
      return username.slice(0, 8) + '...';
    } else {
      const match = username.match(/[A-Z][a-z]*/g);
      return match ? match[0] : username;
    }
  };

  return (
    <Navbar className={styles.navbar} expand="lg" style={{ backgroundColor: '#fff' }} expanded={expanded}>
      <Navbar.Brand href="/index" className={styles.headerLogo}>
        simple<span className={styles.pass}>pass</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className={styles.hamburguesa}>
        <HamburgerToggle isOpen={expanded} toggleMenu={toggleNavbar} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          {!estaAutenticado ? (
            <>
              <SearchForm />
              <Nav.Link href="/login" className={styles.navItem}>Iniciar sesión</Nav.Link>
              <Nav.Link href="/signup" className={styles.navItem}>Registrarme</Nav.Link>
              <Nav.Link href="/crearEvento" className={`${styles.navItem} ${styles.navItemEspecial}`}>Crear evento <FontAwesomeIcon icon={faPlus} /></Nav.Link>
            </>
          ) : userDetails.rol === 'STAFF' ? (
            <>
              <Nav.Link href="/mis-eventos" className={`${styles.navItem} ${styles.navItemEspecial}`}>Mis eventos <FontAwesomeIcon icon={faCalendarDays} /></Nav.Link>
              <Nav.Link href="/perfil" className={styles.navItem}>{formatUsername(userDetails.username)} <FontAwesomeIcon icon={faUser} /></Nav.Link>
              <Nav.Link onClick={handleLogout} className={`${styles.navItem} ${styles.cerrarS}`}>Cerrar sesión</Nav.Link>
            </>
          ) : (
            <>
              {userDetails.rol === 'USER' && (
                <>
                  <SearchForm />
                  <Nav.Link href="/perfil" className={styles.navItem}>{formatUsername(userDetails.username)} <FontAwesomeIcon icon={faUser} /></Nav.Link>
                  <Nav.Link href="/mis-tickets" className={styles.navItem}>Mis tickets <img src='https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/login.signup.signupProductor/favicon.png' style={{ height: 16, width: 16 }} /></Nav.Link>
                  <Nav.Link href="/mis-consumiciones" className={styles.navItem}>Mis consumiciones <FontAwesomeIcon icon={faBottleWater} /></Nav.Link>
                </>
              )}
              {userDetails.rol === 'PRODUCTOR' && (
                <>
                  <Nav.Link href="/perfil" className={styles.navItem}>{formatUsername(userDetails.username)} <FontAwesomeIcon icon={faUser} /></Nav.Link>
                  <Nav.Link href="/panel-productor" className={styles.navItem}>Panel de control <FontAwesomeIcon icon={faCalendarDays} /></Nav.Link>
                  <Nav.Link href="/crearEvento" className={`${styles.navItem} ${styles.navItemEspecial}`}>Crear evento <FontAwesomeIcon icon={faPlus} /></Nav.Link>
                </>
              )}
              <Nav.Link onClick={handleLogout} className={`${styles.navItem} ${styles.cerrarS}`}>Cerrar sesión</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;