import React from 'react';
import styles from './WhatsAppButton.module.scss'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = ({ phoneNumber, message, contenido, className }) => {
  const encodedMessage = encodeURIComponent(message);

  return (
    <a 
      href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`} 
      target="_blank" 
      rel="noopener noreferrer" 
      className={`${styles.whatsAppButton} ${className}`}
    >
      <FontAwesomeIcon icon={faWhatsapp} /> {contenido || "Hablar con asistente"}
    </a>
  );
};

export default WhatsAppButton;

