import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from './EmailButton.module.scss'; // Ajusta la ruta según sea necesario

const EmailButton = ({ emailAddress, subject, body, className }) => {
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  return (
    <a 
      href={`mailto:${emailAddress}?subject=${encodedSubject}&body=${encodedBody}`} 
      className={`${styles.emailButton} ${className}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faEnvelope} /> Enviar correo
    </a>
  );
};

export default EmailButton;

