import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  faMoneyBillWave,
  faGears,
  faChair,
  faBottleWater,
  faCashRegister,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import styles from './EventoCardProductor.module.scss';

// Método para codificar strings y pasarlos por URL sin perder la forma
const encodeStringForURL = (str) => {
  return encodeURIComponent(str);
};

// Componente principal
const EventoCardProductor = ({ evento, auth }) => {
  const [mostrarMas, setMostrarMas] = useState(false);
  const nombreEvento = encodeStringForURL(evento.nombre);

  const handleMostrarMas = () => {
    setMostrarMas(!mostrarMas);
  };

  return (
    <div className={styles.cardEvento}>
      <div className={styles.contDatosEvento}>
        <div className={styles.contImgEvento}>
          <img
            src={evento.imagenUrl ? evento.imagenUrl : "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banner+evento+sin+imagen/banner-evento-por-defeecto.webp"}
            alt="Imagen del evento"
          />
        </div>
        <div className={styles.detallesEvento}>
          <h2>{evento.nombre}</h2>
          <p className={styles.caracteristica}>Fecha:<span> {evento.fechaRealizacion}</span></p>
          <p className={styles.caracteristica}>Id: <span>{evento.id}</span></p>
        </div>
      </div>
      
      <div className={styles.contBotonesEvento}>
        {/* Condiciones para admin */}
        {auth == "2" && (
          <>
            <Link to={`/evento-configuracion/${evento.id}/${nombreEvento}/${encodeURIComponent(evento.imagenUrl)}`} className={styles.botonEvento}>
              <button className={styles.botonConfiguracion}>
                <div className={styles.contenedorBtn}>
                  <div className={styles.contIcono}>
                    <FontAwesomeIcon icon={faGears} />
                  </div>
                  <span>Configuraciones</span>
                </div>
              </button>
            </Link>
            <Link to={`/informe-ventas/${evento.id}`} className={styles.botonEvento}>
              <button className={styles.botonConfiguracion}>
                <div className={styles.contenedorBtn}>
                  <div className={styles.contIcono}>
                    <FontAwesomeIcon icon={faMoneyBillWave} />
                  </div>
                  <span>Informe de ventas</span>
                </div>
              </button>
            </Link>
          </>
        )}

        {/* Condiciones para staff */}
        {auth == "3" && (
          <>
            <Link to={`/scanner-ticket/${evento.id}`} className={styles.botonEvento}>
              <button className={styles.botonConfiguracion}>
                <div className={styles.contenedorBtn}>
                  <div className={styles.contIcono}>
                    <FontAwesomeIcon icon={faQrcode} />
                  </div>
                  <span>Escanear tickets</span>
                </div>
              </button>
            </Link>

            {!mostrarMas && (
              <button className={styles.botonVerMas} onClick={handleMostrarMas}>
                Ver más <Icon icon="solar:alt-arrow-down-bold" />
              </button>
            )}

            {mostrarMas && (
              <>
                <Link to={`/scanner-butaca/${evento.id}`} className={styles.botonEvento}>
                  <button className={styles.botonConfiguracion}>
                    <div className={styles.contenedorBtn}>
                      <div className={styles.contIcono}>
                        <FontAwesomeIcon icon={faChair} />
                      </div>
                      <span>Escanear butacas</span>
                    </div>
                  </button>
                </Link>
                <Link to={`/scanner-consumiciones/${evento.id}`} className={styles.botonEvento}>
                  <button className={styles.botonConfiguracion}>
                    <div className={styles.contenedorBtn}>
                      <div className={styles.contIcono}>
                        <FontAwesomeIcon icon={faBottleWater} />
                      </div>
                      <span>Escanear consumiciones</span>
                    </div>
                  </button>
                </Link>
                <Link to={`/cajas/${evento.id}/${nombreEvento}`} className={styles.botonEvento}>
                  <button className={styles.botonConfiguracion}>
                    <div className={styles.contenedorBtn}>
                      <div className={styles.contIcono}>
                        <FontAwesomeIcon icon={faCashRegister} />
                      </div>
                      <span>Cantina</span>
                    </div>
                  </button>
                </Link>
                <button className={styles.botonVerMas} onClick={handleMostrarMas}>
                  Ver menos <Icon icon="solar:alt-arrow-up-bold" />
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EventoCardProductor;