import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapLocationDot,
  faDollarSign,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../Pages/evento/EventoDetalles/EventoDetalles.module.scss";

const EventoDetalles = ({ evento, ubicacion, precioDesde, formatearFecha }) => {
  return (
    <section
      className={styles.contEventoDetalles}
    >
      <img className={styles.imgFondo} src={evento.imagenUrl || 'https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banner+evento+sin+imagen/banner-evento-por-defeecto.webp'} alt="fondo" srcset="" />
        
      <div className={styles.contSeccionImgYDatos}>

        <div className={styles.contImgEventoDetails}>
          <img
            className={styles.imgEvento}
            src={evento.imagenUrl ? evento.imagenUrl : "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banner+evento+sin+imagen/banner-evento-por-defeecto.webp"}
            alt="Imagen del Evento"
          />
        </div>

        <div className={styles.eventDetails}>
          <div className={styles.contEventTitle}>
            <h5 className={styles.eventTitle}>{evento.nombre || "Cargando..."}</h5>
          </div>
          <div className={styles.atributoCard}>
            <div className={styles.contIcon}>
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <p>
              Fecha: <span><b>{formatearFecha(evento.fechaRealizacion)}</b></span>
            </p>
          </div>
          <div className={styles.atributoCard}>
            <div className={styles.contIcon}>
              <FontAwesomeIcon icon={faMapLocationDot} />
            </div>
            <p>Ubicación: <span>{ubicacion}</span></p>
          </div>
          <div className={styles.atributoCard}>
            <div className={styles.contIcon}>
              <FontAwesomeIcon icon={faDollarSign} />
            </div>
            <p>Precios desde: <b>{precioDesde}</b></p>
          </div>
          <div className={styles.atributoCard}>
            <div className={styles.contIcon}>
              <FontAwesomeIcon icon={faGear} />
            </div>
            <p>Producción: <span>{evento.productor}</span></p>
          </div>
          <p className={styles.descripcionEvento}>{evento.descripcion}</p>
        </div>
      </div>
    </section>
  );
};

export default EventoDetalles;
