import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import DetallesUsuarioModal from '../DetallesUsuarioModal/DetallesUsuarioModal';
import TerminosYCondicionesModal from '../TerminosYCondicionesModal/TerminosYCondicionesModal';
import styles from './Trans.module.scss';
import { useAuth } from '../../Context/AuthProvider';

const Layout = () => {
  const location = useLocation();
  const [transitionClass, setTransitionClass] = useState('');
  const [showDetallesModal, setShowDetallesModal] = useState(false);
  const [showTerminosModal, setShowTerminosModal] = useState(false);
  const { necesitaCompletarInformacion, necesitaAceptarTerminos } = useAuth();

  useEffect(() => {
    // Establecer la clase para iniciar la transición
    setTransitionClass(styles.pageTransitionEnterActive);
    // Configurar una lógica para remover la clase después de que la transición se complete
    const timer = setTimeout(() => {
      setTransitionClass('');
    }, 300); // Asegura que este tiempo coincida con la duración de tu transición CSS

    return () => clearTimeout(timer);
  }, [location.pathname]); // Dependencia en la ruta actual

  useEffect(() => {
    if (necesitaCompletarInformacion && location.pathname !== '/signup' && location.pathname !== '/login') {
      setShowDetallesModal(true);
    } else if (!necesitaCompletarInformacion && necesitaAceptarTerminos) {
      setShowTerminosModal(true);
    }
  }, [location, necesitaCompletarInformacion, necesitaAceptarTerminos]);

  const handleCloseDetallesModal = () => setShowDetallesModal(false);
  const handleCloseTerminosModal = () => setShowTerminosModal(false);

  return (
    <div>
      <Header />
      <DetallesUsuarioModal show={showDetallesModal} handleClose={handleCloseDetallesModal} />
      <TerminosYCondicionesModal show={showTerminosModal} handleClose={handleCloseTerminosModal} />
      {/* Envuelve el Outlet (y por lo tanto, el contenido de la página) en un div para aplicar las transiciones */}
      <div className={transitionClass}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;