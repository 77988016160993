    import React, { useRef } from 'react';
    import html2canvas from 'html2canvas';
    import styles from './Ticket.module.scss'; 
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faDownload } from "@fortawesome/free-solid-svg-icons";

    const Ticket = ({ ticket }) => {
        const ticketRef = useRef(null);
        // Asumiendo que `descargarEntrada` es una función que necesitas definir o importar.
        const descargarEntrada = () => {
            if (ticketRef.current) {
                html2canvas(ticketRef.current, { allowTaint: true, useCORS: true }).then(canvas => {
                    const link = document.createElement('a');
                    link.href = canvas.toDataURL('image/png');
                    link.download = `TICKET-ID:${ticket.id}.png`; // Ajusta el nombre del archivo aquí
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch(error => {
                    console.error('Algo salió mal!', error);
                });
            }
        };

        // Genera query string para evitar caché en imágenes
        const randomQueryStringVerificado = "?nocache=" + new Date().getTime();
        const srcVerificado = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Tickets/Verified.svg.png" + randomQueryStringVerificado;

        return (
            <div ref={ticketRef} className={styles.ticketCont}>
                <div className={styles.logoTicket}>
                    <div className={styles.detallesTicket}>
                        <p className={styles.textoVerificado}>
                            TICKET SIMPLEPASS VERIFICADO <img src={srcVerificado} alt="Verificado" crossOrigin="anonymous" />
                        </p>
                        <p className={styles.id}>ID: {ticket.id}</p>
                    </div>
                    <div className={styles.contTitulo}>
                        <h1>simple<span>pass</span></h1>
                    </div>
                </div>

                <div className={styles.datosTicket}>
                    <h2>{ticket.titulo}</h2>
                    <span>Tipo de entrada: {ticket.tipoEntrada}</span>
                    <span id={styles.infoUsuario}>{ticket.nombreUsuario}</span>
                    <span id={styles.dni}>DNI: {ticket.dni}</span>
                </div>

                <div className={styles.imagenQR}>
                    <img src={ticket.srcQr + "?nocache=" + new Date().getTime()} alt="Código QR" crossOrigin="anonymous" />
                </div>

                <button className={styles.btnDescargar} onClick={descargarEntrada}>
                    Descargar <FontAwesomeIcon icon={faDownload} />
                </button>
            </div>
        );
    };

    export default Ticket;
