import React, {useEffect} from 'react'
import styles from "./ExitoCreacionEvento.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ExitoCreacionEvento = () => {

  //Modificacion de head
  useEffect(() => {
    document.title = "Evento creado exitosamente";
  }, []);

  return (
    <section className={styles.eventoCreadoSection}>
      <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/newsletters/group-51.svg" alt="Evento"
        className={styles.eventoImg}/>
      <h2>¡Tu cuenta de mercado pago fue conectada a nuestros servidores con éxito!</h2>

      <p className={styles.mpVinculadoSeccion}>
        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/Mercado-Pago-Logo.webp" className={styles.mp} alt=""/>
      </p>

      <div className={styles.fondoDiv}>
      <p>Ahora solo nos queda terminar de configurar el resto por nuestra parte y podrás cobrar los pagos de tus eventos en el momento.</p>
      </div>
      <h3>¿Dudas o consultas?</h3>
      <p className={styles.contactInfo}>
      <FontAwesomeIcon icon={faWhatsapp} />
        +543434653077<br/>
        <FontAwesomeIcon icon={faEnvelope} />
        simplesistemasweb@gmail.com
      </p>
    </section>
  )
}

export default ExitoCreacionEvento
