import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './DetallePagoExito.module.scss';
import { useAuth } from "../../Context/AuthProvider";

const DetallePagoExito = () => {
    const [detallesPago, setDetallesPago] = useState({
        pagoId: '',
        estado: '',
        medioPago: '',
        total: ''
    });
    const { login, estaAutenticado } = useAuth();
    const location = useLocation();

    useEffect(() => {
        document.title = "Compra exitosa";
        window.scrollTo(0, 0);
        verificarYActualizarSesion();
    }, []);

    const verificarYActualizarSesion = () => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            const params = new URLSearchParams(location.search);
            const externalReference = params.get('external_reference');
            if (externalReference) {
                const [idEvento, montoTotal, jwt, username, rol] = externalReference.split("|");
                const jwtLimpio = jwt.split(" ")[1];
                if (jwt) {
                    const user = {
                        jwt: jwtLimpio,
                        username: username,
                        rol: rol
                    };
                    login(user, false);  // Actualiza la sesión y contexto global con `mantenerSesion` activado
                }
            }
        }
        extractPaymentDetails();
    };

    const extractPaymentDetails = () => {
        const params = new URLSearchParams(location.search);
        const externalReference = params.get('external_reference') || "";
        const monto = externalReference.split("|")[1] || "";

        setDetallesPago({
            pagoId: params.get("payment_id") || "",
            estado: params.get("status") === "approved" ? "Aprobado" : "Pendiente",
            medioPago: params.get("payment_type") || "",
            total: "$" + monto
        });
    };

    return (
        <main className={styles.exitoBody}>
            <section className={styles.bodyDetalle}>
                <h1>
                    Tu pago fue aprobado <FontAwesomeIcon icon={faCheck} style={{ color: '#47BD8F' }} />
                </h1>
                <div className={styles.fondoSub}>
                    <h4 className={styles.aclaracionExito}>
                        Tus tickets están siendo procesados, en 5 minutos podrás verlos y además te llegarán a tu correo.
                    </h4>
                </div>
                <div className={styles.tablaDetallePago}>
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan="2">Detalles del pago:</th>
                            </tr>
                            <tr>
                                <td>Pago id:</td>
                                <td className={styles.resultado}>{detallesPago.pagoId}</td>
                            </tr>
                            <tr>
                                <td>Medio de pago:</td>
                                <td className={styles.resultado}>{detallesPago.medioPago}</td>
                            </tr>
                            <tr>
                                <td>Estado del pago:</td>
                                <td className={styles.resultado}>{detallesPago.estado}</td>
                            </tr>
                            <tr className={styles.br}>
                                <td className={styles.br10bl}>Total:</td>
                                <td className={styles.resultado}>{detallesPago.total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Link to="/mis-tickets" className={`${styles.botonVerTickets} ${styles.btnExitoQrs}`}>
                    Ver mis tickets QRs
                </Link>
            </section>
        </main>
    );
};

export default DetallePagoExito;