import React, { useState, useEffect, useRef } from "react";
import styles from "./TicketScanner.module.scss";
import { QrReader, QrReaderViewFinder } from "reactjs-qr-code-reader";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../Context/Variables";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';


const TicketScanner = () => {
  const { eventoId } = useParams();
  const [lastResult, setLastResult] = useState("No se ha escaneado aún.");
  const ticketsListRef = useRef([]);
  const [loaderVisible, setLoaderVisible] = useState(true);
  const [jwt, setJwt] = useState("");
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [isBodyVisible, setIsBodyVisible] = useState(false);
  const [scanRecords, setScanRecords] = useState([]);
  const [puedeLeer, setPuedeLeer] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const ticketIdRef = useRef(null);
  const navigate = useNavigate();
  const ultimoEscaneoRef = useRef(Date.now());

  // Verificar JWT al cargar el componente
  useEffect(() => {
    document.title = "Escáner de tickets";
    window.scrollTo(0, 0);
    ensureAuthenticated()
  }, []);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      setJwt(details.jwt);
      fetchTickets(eventoId);
      return details;
    }
  };

  useEffect(() => {

    fetchTickets(eventoId);

    const intervalId = setInterval(() => {
      fetchTickets(eventoId);
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventoId]);


  function fetchTickets(eventoId) {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    }
    const details = JSON.parse(userDetails);
    if (!details || !details.jwt) {
      navigate("/login");
    } else {
      const token = details.jwt;

      fetch(variables.API_BASE_URL + `/api/tickets/validateUserAndListTickets?eventoId=${eventoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Respuesta de red no fue ok');
          }
          return response.json();
        })
        .then((data) => {
          ticketsListRef.current = data;
          console.log("CANTIDAD DE TICKETS TRÍDOS: " +  data.length);
          setIsLoaderVisible(false)
          setIsBodyVisible(true)
        })
        .catch((error) => console.error("Error fetching tickets:", error))
        .finally(() => {

        });
    }
  }

  const validarTicketPorId = (eventoId, ticketId) => {
    setIsLoading(true);
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    }

    const details = JSON.parse(userDetails);
    const token = details.jwt;
    fetch(
      variables.API_BASE_URL + `/api/tickets/validateById?eventoId=${eventoId}&ticketId=${ticketId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(async response => {
        const data = await response.json(); // Intenta obtener la respuesta en formato JSON

        if (!response.ok) {
          // Maneja las respuestas no 'ok', pero aún así obtiene la data para mostrar al usuario
          throw new Error(data[0] || `HTTP error! Status: ${response.status}`);
        }

        return data;
      })
      .then(data => {
        // Encuentra y actualiza el ticket en la lista del lado del cliente
        const ticketIndex = ticketsListRef.current.findIndex(ticket => ticket.id === ticketId);
        if (ticketIndex !== -1) {
          // Suponiendo que 'data' contiene el estado actualizado del ticket
          const updatedTicket = { ...ticketsListRef.current[ticketIndex], ...data };
          const updatedTicketsList = [...ticketsListRef.current];
          updatedTicketsList[ticketIndex] = updatedTicket;
          ticketsListRef.current = updatedTicketsList;
        }
        const nowInArgentina = moment.tz("America/Argentina/Buenos_Aires");
        const updatedNow = nowInArgentina.format("HH:mm");

        // Verifica si el ticket es válido antes de actualizar los registros
        if (data[0] && data[0].includes("Ticket válido")) {
          updateScanRecords(ticketId, "Válido", updatedNow);
          showAlert(data[0], "success");
        } else {
          showAlert(data[0], "warning");
        }
      })
      .catch(error => {
        console.error("Error al validar el ticket:", error);
        // Asegúrate de mostrar el mensaje del error, incluso si es un error HTTP intencionado con información útil
        showAlert(error.message, "warning");
      })
      .finally(() => {
        setIsLoading(false); // Finaliza la carga
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ticketIdRef.current && ticketIdRef.current.value) {
      validarTicketPorId(eventoId, ticketIdRef.current.value);
    }
  };

  function handleError() {
    console.log("sxx");
  }

  const handleScan = (result) => {
    if (result && puedeLeer && (Date.now() - ultimoEscaneoRef.current >= 4000)) {
      ultimoEscaneoRef.current = Date.now();
      const codigoQr = result.text;
      validateTicketClientSide(codigoQr);
    }
  };

  const validateTicketClientSide = (codigoQr) => {
    const ticketsList = ticketsListRef.current;
    const ticketIndex = ticketsList.findIndex(ticket => ticket.codigoQr === codigoQr);

    if (ticketIndex === -1) {
      setLastResult("Ticket no encontrado o no es para este evento.");
      updateScanRecords(codigoQr.slice(-4), "No encontrado o no válido", "N/A");
      showAlert("Ticket no encontrado o no es para este evento.", "error");
      return;
    }

    const ticket = ticketsList[ticketIndex];
    if (ticket.esValido) {
      const nowInArgentina = moment.tz("America/Argentina/Buenos_Aires");
      const updatedNow = nowInArgentina.format("HH:mm");

      // Actualiza el ticket en la lista para reflejar que ha sido usado
      ticketsListRef.current[ticketIndex] = { ...ticket, esValido: false, fechaYHoraUsado: nowInArgentina };

      setLastResult(`Ticket ${ticket.id}: Válido. Puede ingresar.`);
      updateScanRecords(ticket.id, "Válido", updatedNow);
      showAlert("Ticket válido. Puede ingresar.", "success");

      // Asume que invalidateTicketAsync actualiza el backend
      invalidateTicketAsync(ticket.id, ticket.codigoQr);
    } else {
      // Parsea correctamente la fecha de uso para calcular `timeAgo` y formatear `usedAt`
      const usedAt = ticket.fechaYHoraUsado ? moment(ticket.fechaYHoraUsado) : moment();
      console.log("FECHA " + ticket.fechaYHoraUsado);
      const formattedUsedAt = usedAt.format("HH:mm");
      const diff = moment().diff(usedAt);
      const duration = moment.duration(diff);

      const timeAgo = `${duration.hours()}h ${duration.minutes()}min`;

      updateScanRecords(ticket.id, `Ya utilizado hace ${timeAgo}`, formattedUsedAt);
      setLastResult(ticket.id, `Ya utilizado hace ${timeAgo}`, formattedUsedAt);
      showAlert("Ticket ya utilizado.", "warning");
    }
  };

  const updateScanRecords = (ticketId, result, usedAtFormatted) => {
    setScanRecords(currentRecords => [{ ticketId, result, usedAt: usedAtFormatted }, ...currentRecords]);
  };

  const invalidateTicketAsync = (ticketId, codigoQr) => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    }

    const details = JSON.parse(userDetails);
    const token = details.jwt;

    const requestBody = {
      idTicket: ticketId.toString(),
      eventoId: eventoId,
      codigoQr: codigoQr
    };

    fetch(variables.API_BASE_URL + `/api/tickets/invalidateTicketAsync`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al invalidar el ticket');
        }
        const contentType = response.headers.get("content-type");
        fetchTickets(eventoId);
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        } else {
          // Si no es JSON, asumir éxito y retornar un objeto o mensaje estándar
          return { message: "Operación exitosa, pero la respuesta no es JSON." };
        }
      })
      .then(data => {
        console.log("Respuesta:", data.message || data);
      })
      .catch(error => {
        console.error("Error al invalidar el ticket", error);
      });
  };

  const showAlert = (title, icon) => {
    Swal.fire({
      title,
      icon,
      timer: 4000,
      showConfirmButton: false
    });
  };

  return (
    <section className={styles.bodyScanner}>
      <div
        id="loaderContainer" className={styles.loaderContainer}
        style={{ display: isLoaderVisible ? "flex" : "none" }}
      >
        <div className={styles.loaderCargandoScanner}>
          <div className={styles.box1}></div>
          <div className={styles.box2}></div>
          <div className={styles.box3}></div>
        </div>
        <p className={styles.cargandoCartel} id="cargandoCartel">Cargando scanner...</p>
      </div>

      <div style={{ display: isBodyVisible ? "flex" : "none" }} className={styles.sectionScanner} id="sectionScanner">
        <h2 className={styles.tituloScan}>
          Escáner de tickets <img style={{ height: "18px" }} src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/scanners/codigo-qr+(2).png" />
        </h2>
        <div className={styles.scannerContainer}>
          <div
            id="loader"
            className={styles.loaderScanner}
            style={{ display: loaderVisible ? "block" : "none" }}
          ></div>
          <QrReader
            className={styles.video}
            onRead={handleScan}
            read={puedeLeer}
            onReadError={handleError}
          >
            <QrReaderViewFinder style={{ color: "#862f8b", border: "0px" }} />
          </QrReader>
          <div className={styles.result}>
            <h2>Último resultado:</h2>
            <p>{lastResult}</p>
          </div>

          <div className={styles.scannerRecords}>
            <h3>Registro de resultados</h3>
            <table>
              <thead>
                <tr>
                  <th>ID del Ticket</th>
                  <th>Resultado</th>
                  <th>Horario usado</th>
                </tr>
              </thead>
              <tbody>
                {scanRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{record.ticketId}</td>
                    <td>{record.result}</td>
                    <td>{record.usedAt || 'No dice'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Resto del componente... */}
        <div className={styles.validarTicketId}>
          <h2>Validar Ticket por ID</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="number"
              ref={ticketIdRef}
              placeholder="Ingresa el ID del ticket"
              required
            />
            <SubmitButton
              isDisabled={isLoading}
              color="#c970ce"
              isLoading={isLoading}
              onClick={() => { }}
            >
              Validar
            </SubmitButton>
          </form>
        </div>
      </div>
    </section>
  );
};

export default TicketScanner;
