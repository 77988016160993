import React, { useState, useRef } from 'react';
import estilos from './Testimonios.module.scss';
import { styled } from '@mui/material';

const Testimonios = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const sliderRef = useRef(null);

    const testimonies = [
        {
            img: "https://scontent.fcor13-1.fna.fbcdn.net/v/t39.30808-6/428656893_4155189864707681_6842655158664186076_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=a5f93a&_nc_eui2=AeEejK9EgLmRdx1iBuEFDyQF9rtvF3sM7Ub2u28XewztRvRYZWqOimJb5dl6IjIABcLNYxgZQSWBNePAcKFENaKU&_nc_ohc=St9USH0D-boQ7kNvgElrqt5&_nc_ht=scontent.fcor13-1.fna&oh=00_AYD9BCIjABT5b54EKb3cCuqpYX5YwEfAV-00ps43ameiPw&oe=66D8106E",
            text: "“Con simplepass centralizamos todas las ventas de entradas, butacas y consumiciones. Gracias a eso, ahorramos mucho tiempo y multiplicamos por 5 la venta online del evento.”",
            name: "Ricardo Matteoda",
            event: "Carnavales de Hasenkamp"
        },
        {
            img: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/axel-foto.jpg",
            text: "“Con simplepass vendimos mucho más gracias a que alcanzamos a más público que antes no podía comprar las entradas.”",
            name: "Axel Baron",
            event: "Arizona Disco"
        },
        {
            img: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/Captura-de-pantalla-2024-09-03-a-las-19.04.46.webp",
            text: "“Nos fue muy bien. Me ayudó un montón tener el dinero de inmediato para poder pagar cosas por adelantado y así terminar todos contentos.”",
            name: "Giuliano",
            event: "Bioniks Crew"
        }
    ];

    const handleNext = () => {
        if (currentIndex < testimonies.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStartX - touchEndX > 50) {
            handleNext(); // Swipe Left
        }

        if (touchStartX - touchEndX < -50) {
            handlePrev(); // Swipe Right
        }
    };

    return (
        <div className={estilos.contenedorTestimonios}>
            <h2 className={estilos.titulo}>Testimonio de productores</h2>
            <div
                className={estilos.slider}
                ref={sliderRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div
                    className={estilos.testimonios}
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {testimonies.map((testimonio, index) => (
                        <div className={estilos.testimonioItem} key={index}>
                            <div className={estilos.header}>
                                <div className={estilos.imagen}>
                                    <img src={testimonio.img} alt="Foto del productor" />
                                </div>

                                <div className={estilos.nombreYUbicacion}>
                                    <h4>{testimonio.name}</h4>
                                    <p>{testimonio.event}</p>
                                </div>
                            </div>

                            <p className={estilos.descripcion}>{testimonio.text}</p>

                        </div>
                    ))}
                </div>
            </div>
            <div className={estilos.dots}>
                {testimonies.map((_, idx) => (
                    <span
                        key={idx}
                        className={`${estilos.dot} ${currentIndex === idx ? estilos.active : ''}`}
                        onClick={() => setCurrentIndex(idx)}
                    ></span>
                ))}
            </div>
            <div className={estilos.navigation}>
                <button onClick={handlePrev}>Prev</button>
                <button onClick={handleNext}>Next</button>
            </div>
        </div>
    );
};

export default Testimonios;