import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import "./GraficoSkeleton.scss";
import variables from "../../../Context/Variables";
import styles from "../Ventas por semana/VentasPorSemanaGrafico.module.scss";

const VentasUltimos3Meses = ({ jwt }) => {
  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [tieneVentas, setTieneVentas] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = jwt;
        if (!token) {
          const userDetails =
            sessionStorage.getItem("userDetails") ||
            localStorage.getItem("userDetails");
          const details = JSON.parse(userDetails);
          token = details.jwt;
        }
        if (!token) {
          console.error("Token no disponible");
          return;
        }
        const response = await fetch(
          variables.API_BASE_URL + "/api/ventas/ventasPorSemanaUltimos3Meses",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        
        const jsonData = await response.json();

        console.log(jsonData);

        if (jsonData.length === 0) {
          setTieneVentas(false);
          setCargando(false);
          return;
        } else {

          setTieneVentas(true);
          setCargando(false);

          const transformedData = [
            {
              id: "ventas",
              data: jsonData
                .map((venta) => ({
                  ...venta,
                  sortableDate: new Date(venta.semana), 
                }))
                .sort((a, b) => a.sortableDate - b.sortableDate)
                .map((venta) => ({
                  x: venta.semana.slice(0, 5), 
                  y: venta.montoTotal,
                })),
            },
          ];

          setData(transformedData);
        }
      } catch (error) {
        console.error("Error al obtener los datos de ventas:", error.message);
      }
    };

    fetchData();

  }, [jwt]);

  if (cargando) {
    return (
      <div className="skeleton-container">
        <div className="skeleton-chart"></div>
      </div>
    );
  }

  if (!tieneVentas) {
    return <div className="skeleton-chart sinCarga">

      <h2><b>Grafico de ventas ultimos 3 meses.</b></h2>
      <p>Usted aún no posee ventas.</p>
    </div>;
  }

  return (
    <div className={styles.contGrafico}>
      <h3>Ventas ultimos 3 meses <span>(monto/semanas)</span></h3>
      <ResponsiveLine
        data={data}
        margin={{ top: 20, right: 20, bottom: 50, left: 65 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // Leyendas eliminadas
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // Leyendas eliminadas
        }}
        colors={['#862f8b']}
        pointSize={10}
        pointColor={{ from: "color", modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        theme={{
          fontFamily: "Montserrat, sans-serif",
          fontSize: 12,
          textColor: "#1b1b1b",
          axis: {
            legend: {
              text: {
                fontSize: 16,
                fontWeight: "500",
                fill: "#862f8b",
              },
            },
          },
          grid: {
            line: {
              stroke: "#e4e4e4",
              strokeWidth: 1,
              strokeDasharray: "0",
            },
          },
          legends: {
            text: {
              fill: "#99419d",
            },
          },
        }}
      />
    </div>
  );
};

export default VentasUltimos3Meses;
