import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./MenuConsumiciones.module.scss";

const MenuConsumiciones = ({ productosDisponibles, mostrarTodos, setMostrarTodos, carrito, actualizarCarritoProductos, formatCurrency }) => {
  const consumicionesAMostrar = mostrarTodos ? productosDisponibles : productosDisponibles.slice(0, 3);

  return (
    <div className={styles.contenedorConsumiciones}>
      {consumicionesAMostrar.map((producto) => (
        <div key={producto.id} className={styles.consumisiones}>
          <div className={`${styles.detalleTipoTicket} ${styles.consumision}`}>
            <img width={80} className={styles.imgConsumision} src={producto.imagenUrl} alt={producto.titulo} />
            <div>
              <h4>{producto.titulo}</h4>
              <h5>{formatCurrency(producto.precio)}</h5>
            </div>
          </div>
          <div className={styles.resultadoCantidad}>
            <button className={styles.btnCantidad} onClick={() => actualizarCarritoProductos(producto.id, -1)}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <input type="number" value={carrito.productos[producto.id]?.cantidad || 0} readOnly className={styles.cantidadMenu} />
            <button className={styles.btnCantidad} onClick={() => actualizarCarritoProductos(producto.id, 1)}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
      ))}
      {productosDisponibles.length > 3 && (
        <button onClick={() => setMostrarTodos(!mostrarTodos)} className={styles.verMasBtn}>
          {mostrarTodos ? "Ver menos" : "Ver más"}
        </button>
      )}
    </div>
  );
};

export default MenuConsumiciones;