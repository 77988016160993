import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";  // Importar useNavigate
import EventosContenedor from "../../Components/EventosContenedor/EventosContenedor";
import SearchForm from "../../Components/SearchForm/SearchForm";
import styles from "./HomeContenedor.module.scss";
import Carousel from 'react-bootstrap/Carousel';
import EventosFinalizadosCont from '../../Components/EventosContenedor/EventosFinalizadosCont';

const Home = () => {
  const navigate = useNavigate(); // Instanciar useNavigate

  // Modificación de head
  useEffect(() => {
    document.title = "Home simplepass";
  }, []);

  // URLs de las imágenes
  const images = [
    "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/Fiesta+nacional+de+la+avicultura/Captura-de-pantalla-2024-09-02-a-las-21.11.07.webp",
    "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banners+de+home/1.webp",
    "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banners+de+home/3.webp"
  ];

  // Manejador para redirigir al usuario
  const handleSecondImageClick = () => {
    navigate("/fiesta-nacional-avicultura");
  };

  return (
    <div className={styles.home}>
      <Carousel className={styles.carouselWithRadius} slide={false}>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Slide ${index + 1}`}
              onClick={index === 0 ? handleSecondImageClick : null}
              style={{ cursor: index === 1 ? "pointer" : "default" }} 
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <h2>Próximos eventos</h2>
      <EventosContenedor />
      <h2>Eventos finalizados</h2>
      <EventosFinalizadosCont />
    </div>
  );
};

export default Home;
