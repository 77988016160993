import React, { useState, useEffect, useRef } from 'react';
import styles from "./FiestaNacionalAvicultura.module.scss";
import LazyImage from '../../Components/LazyImage/LazyImage';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import variables from '../../Context/Variables';
import EventCard from '../../Components/EventCard/EventCard';
import EventosNoEncontrados from '../../Components/EventosNoEncontrados/EventosNoEncontrados';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const FiestaNacionalAvicultura = () => {

    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);
    const [estaBuscando, setEstaBuscando] = useState(true);
    const eventosBuscados = useRef(false);

    useEffect(() => {
        document.title = "Fiesta Nacional de la Avicultura";
    }, []);

    useEffect(() => {
        const busqueda = "Fiesta Nacional de la Avicultura";

        if (!eventosBuscados.current) {
            eventosBuscados.current = true;
            const buscarEventos = () => {
                axios.get(`${variables.API_BASE_URL}/api/eventos/search?q=${busqueda}`)
                    .then(response => {
                        setEventos(response.data);
                        setEstaBuscando(false);
                    })
                    .catch(err => {
                        setError(err.message);
                        setEstaBuscando(false);
                    });
            }
            buscarEventos();
        }

    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <LazyImage className={styles.imagenHeader} src={"https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/Fiesta+nacional+de+la+avicultura/Captura-de-pantalla-2024-09-02-a-las-21.11.07.webp"} alt={"header de fiesta nacional de la avicultura."} />

                <h1>Entradas oficiales para la <b>Fiesta Nacional de la Avicultura</b></h1>
                <h2>Compra tus tickets acá <Icon width={20} icon="lucide:tickets" /></h2>
                <div className={styles.contenedorMetodosDePago}>
                    <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/logo-Mastercard.webp" className={styles.mc} alt="" srcSet="" />
                    <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/Mercado-Pago-Logo.webp" className={styles.mp} alt="" srcSet="" />
                    <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/new-visa-logo-high-quality-png-latest.webp" className={styles.visa} alt="" srcSet="" />
                    <div className={styles.tarjeta}><span>Crédito</span></div>
                    <div className={styles.tarjeta}><span>Débito</span></div>
                </div>
            </div>

            <section className={styles.eventos}>
                {estaBuscando ? (
                    <div style={{ minHeight: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LoaderSimplepass />
                    </div>
                ) : (
                    eventos.length === 0 ? (
                        <EventosNoEncontrados query={"Fiesta nacional de la avicultura."} />
                    ) : (
                        eventos.map(evento => <EventCard key={evento.id} evento={evento} />)
                    )
                )}
            </section>

        </div>
    )
}

export default FiestaNacionalAvicultura