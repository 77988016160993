import React, { useEffect, useState } from "react";
import TicketConsumicion from "../../Components/TicketConsumicion/TicketConsumicion";
import styles from './CuerpoTicketsConsumiciones.module.scss'
import variables from "../../Context/Variables";
import TicketConsumicionSkeleton from "../../Components/TicketConsumicion/Skeleton/TicketConsumicionSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBottleWater
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../Components/ErrorPage/ErrorPage";

const CuerpoTicketsConsumiciones = () => {
  const [ticketsIndividuales, setTicketsIndividuales] = useState([]);
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState("");

  //Modificacion de head
  useEffect(() => {
    document.title = "Mis consumciones";
    asegurarQueEsteAutenticadoYBucarTickets();
  }, []);

  const asegurarQueEsteAutenticadoYBucarTickets = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);

      if (!details || !details.jwt) {
        navigate("/login");
      } else {
        const jwt = details.jwt;
        fetchTicketsIndividuales(jwt);
      }
    }
  };

  const fetchTicketsIndividuales = async (jwt) => {
    try {
      const response = await fetch(variables.API_BASE_URL + "/api/ticketConsumiciones/consumiciones/comprados", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setTicketsIndividuales(data);
        setCargando(false)
      } else {
        console.log("No se encontraron tickets individuales");
        setCargando(false)
      }
    } catch (error) {
      console.error("Error fetching individual tickets:", error);
      setError("Error al buscar los tickets: " + error);
      setCargando(false)
    }
  };  

  if (cargando) return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <h2 style={{ color: "#ccc", fontSize: "20px", marginTop: "30px", width: "70%", textAlign: "center" }}>Cargando tickets de consumición...</h2>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
        <TicketConsumicionSkeleton />
        <TicketConsumicionSkeleton />
        <TicketConsumicionSkeleton />
      </div>
    </div>
  );
  if (error) return <ErrorPage />;

  return (
    <main className={styles.cuerpoTickets}>
      {/* Contenido informativo aquí */}
      <div className={styles.contDescripcion}>
        <h2 className={styles.tituloMisConsumiciones}>Mis tickets de consumiciones <FontAwesomeIcon icon={faBottleWater} /></h2> 
        <div className={styles.fondoSub}>
          <p>Podés <b>descargarlos</b>, <b>hacerle captura</b> o{" "}
            <b>mostrarlos desde acá</b> y presentarlos en los puntos de venta del evento para <b>retirar</b> tu producto.</p>
        </div>
      </div>
      <div className={styles.lineaDivisora}></div>
      <section className={styles.seccionConsumiciones}>
        {ticketsIndividuales.length > 0 ? (
          ticketsIndividuales.map((ticket) => (
            <TicketConsumicion key={ticket.id} ticket={ticket} />
          ))
        ) : (
          <p style={{width:"80%", textAlign:"center", margin:"40px auto"}}>No tenes compras realizadas de consumiciones.</p>
        )}
      </section>
    </main>
  );
};

export default CuerpoTicketsConsumiciones;
