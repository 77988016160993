import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Icon } from '@iconify/react';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import styles from './Cajas.module.scss';
import { Link } from 'react-router-dom';
import variables from '../../Context/Variables';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import Swal from 'sweetalert2';

const Cajas = () => {
  const [cajas, setCajas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newCajas, setNewCajas] = useState([]);
  const { estaAutenticado, jwt } = useAuth();
  const { eventoId, nombre } = useParams();
  const [formAierto, setFormAbierto] = useState(false);
  const abortControllerRef = useRef(null);

  const cancelPreviousRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  };

  const fetchCajas = async (jwt) => {
    try {
      const response = await axios.get(`${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        signal: abortControllerRef.current.signal,
      });

      setCajas(response.data.cajas || []);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request cancelled');
      } else {
        console.error('Error fetching cajas', error);
        setError('Failed to fetch cajas for the event.');
        setLoading(false);

        // Mostrar SweetAlert en caso de error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo cargar las cajas del evento.',
        });
      }
    }
  };

  const handleAddNewCaja = () => {
    setNewCajas([...newCajas, { nombre: '', isSubmitting: false }]);
    setFormAbierto(true);
  };

  const handleNombreChange = (index, value) => {
    const updatedNewCajas = [...newCajas];
    updatedNewCajas[index].nombre = value;
    setNewCajas(updatedNewCajas);
  };

  const handleSubmitCaja = async (index) => {
    const caja = newCajas[index];
    if (!caja.nombre.trim()) {
      return; // No enviar si el nombre está vacío
    }

    try {
      const response = await axios.post(`${variables.API_BASE_URL}/api/cantina/${eventoId}/agregarCaja`,
        { nombre: caja.nombre },
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });

      setCajas([...cajas, response.data]);
      setNewCajas(newCajas.filter((_, i) => i !== index)); // Elimina el formulario de la nueva caja enviada

      // Mostrar SweetAlert en caso de éxito
      Swal.fire({
        icon: 'success',
        title: 'Caja creada',
        text: 'La nueva caja ha sido creada con éxito.',
      });
    } catch (error) {
      console.error('Error creating caja', error);
      setError('Failed to create caja.');

      // Mostrar SweetAlert en caso de error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo crear la caja.',
      });
    }
  };

  useEffect(() => {
    if (!estaAutenticado) {
      return;
    }
    window.scroll(0, 0);

    cancelPreviousRequest();
    fetchCajas(jwt);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [estaAutenticado, jwt]);

  if (!estaAutenticado) {
    return <NoPermissionPage />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.cajasContainer}>
      <div className={styles.header}>
        <button className={styles.volver} onClick={() => { window.history.back() }}>
          <Icon width={30} icon="solar:arrow-left-linear" />
        </button>
      </div>
      <h2 className={styles.titulo}>Cajas de {decodeURIComponent(nombre)}</h2>
      {(cajas.length === 0 && !formAierto) && (
        <div className={styles.noCajas}>
          <p>Usted aún no posee cajas.</p>
        </div>
      )}

      <div className={styles.contCajasYAgregar}>

        <ul className={styles.listaCajas}>
          {loading ?
            <>
              <li className={styles.caja}>
                <a>
                  <div className={styles.conIconSkeleton}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>Cargando...</p>
                </a>
              </li>
              <li className={styles.caja}>
                <a>
                  <div className={styles.conIconSkeleton}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>Cargando...</p>
                </a>
              </li>
              <li className={styles.caja}>
                <a>
                  <div className={styles.conIconSkeleton}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>Cargando...</p>
                </a>
              </li>
            </>
            :
            cajas.map((caja) => (
              <li key={caja.id} className={styles.caja}>
                <Link to={`/caja/${eventoId}/${caja.id}`}>
                  <div className={styles.contIconCaja}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>{caja.nombre}</p>
                </Link>
              </li>
            ))}
        </ul>

        {newCajas.map((caja, index) => (
          <div key={index} className={styles.newCajaForm}>
            <div className={styles.contIconCaja}>
              <Icon icon="ph:cash-register-light" className={styles.icon} />
            </div>
            <input
              type="text"
              value={caja.nombre}
              onChange={(e) => handleNombreChange(index, e.target.value)}
              placeholder="Nombre de la nueva caja"
              className={styles.inputCaja}
            />
            <SubmitButton
              isDisabled={caja.isSubmitting}
              isLoading={caja.isSubmitting}
              onClick={() => handleSubmitCaja(index)}
              className={styles.buttonSubmit}
            >
              Guardar caja <Icon width={15} icon="mynaui:save" />
            </SubmitButton>
          </div>
        ))}

        <div className={styles.addCaja} onClick={handleAddNewCaja}>
          <Icon icon="gravity-ui:plus" className={styles.addIcon} />
        </div>

      </div>

    </div>
  );
};

export default Cajas;