import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTicket,
  faAddressCard,
  faBottleWater,
  faKey,
  faPeopleGroup,
  faRightToBracket,
  faUsersViewfinder
} from "@fortawesome/free-solid-svg-icons";
import {
  faCreditCard
} from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import styles from "./Perfil.module.scss";

const Perfil = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [rol, setRol] = useState("");

  useEffect(() => {
    document.title = "Perfil";
    const userDetailsString = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');

    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      setUsername(userDetails.username);
      setRol(userDetails.rol);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <section className={styles.perfilUsuario}>
      <div>
        <h1>¡Bienvenido/a {username.split(" ")[0]}!</h1>
      </div>
      <p>Desde acá podrás ver todos tus datos, modificarlos y también ver todas tus entradas.</p>

      <div>
        <h4>Panel de control</h4>
      </div>

      <div className={`${styles.actionUser} ${(rol === "PRODUCTOR" || rol === "STAFF") ? styles.actionUserCenter : ''}`}>
        {/* Muestra todos los botones si el rol es USER */}
        {rol === "USER" && (
          <>
            <Link className={styles.perfilAccion} to="/mis-tickets">
              <FontAwesomeIcon icon={faTicket} />
              Mis entradas
            </Link>
            <Link style={{ fontSize: 15 }} className={styles.perfilAccion} to="/mis-consumiciones">
              <FontAwesomeIcon icon={faBottleWater} />
              Mis consumiciones
            </Link>
            <Link className={styles.perfilAccion} to="/mis-datos">
              <FontAwesomeIcon icon={faAddressCard} />
              Mis datos
            </Link>
            <Link className={styles.perfilAccion} to="/mis-pagos">
              <FontAwesomeIcon icon={faCreditCard} />
              Mis pagos
            </Link>
            <Link className={styles.perfilAccion} to="/cambiar-contraseña">
              <FontAwesomeIcon icon={faKey} />
              Cambiar contraseña
            </Link>
            <Link onClick={logout} className={styles.perfilAccion} to="/login">
              <FontAwesomeIcon icon={faRightToBracket} />
              Cerrar sesión
            </Link>
          </>
        )}
        {/* Muestra una selección limitada de botones si el rol es PRODUCTOR */}
        {(rol === "PRODUCTOR") && (
          <>
            <Link className={styles.perfilAccion} to="/mis-datos">
              <FontAwesomeIcon icon={faAddressCard} />
              Mis datos
            </Link>
            <Link className={styles.perfilAccion} to="/cambiar-contraseña">
              <FontAwesomeIcon icon={faKey} />
              Cambiar contraseña
            </Link>
            <Link className={styles.perfilAccion} to="/gestion-rrpps">
              <FontAwesomeIcon icon={faPeopleGroup} />
              Gestionar RRPPs
            </Link>
            <Link className={styles.perfilAccion} to="/gestion-staff">
              <FontAwesomeIcon icon={faUsersViewfinder} />
              Gestionar STAFF
            </Link>
            <Link onClick={logout} className={styles.perfilAccion} to="/login">
              <FontAwesomeIcon icon={faRightToBracket} />
              Cerrar sesión
            </Link>
          </>
        )}
        {(rol === "STAFF") && (
          <>
            <Link className={styles.perfilAccion} to="/mis-datos">
              <FontAwesomeIcon icon={faAddressCard} />
              Mis datos
            </Link>
            <Link className={styles.perfilAccion} to="/cambiar-contraseña">
              <FontAwesomeIcon icon={faKey} />
              Cambiar contraseña
            </Link>
            <Link onClick={logout} className={styles.perfilAccion} to="/login">
              <FontAwesomeIcon icon={faRightToBracket} />
              Cerrar sesión
            </Link>
          </>
        )}
      </div>
    </section>
  );
};

export default Perfil;

