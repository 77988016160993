import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import styles from "./Footer.module.scss"; // Asume que tienes estilos específicos
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
        <article>
          <h1>
            simple<span>pass</span>
          </h1>
          <small>Tus eventos a un click</small>
          <div className={styles.redes}>
          <a
            className={styles.linkRed}
            href="https://www.facebook.com/profile.php?id=100076488752786"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            className={styles.linkRed}
            href="https://www.instagram.com/simplepass.com.ar?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        </article>
        
        <article>
          <Link className={styles.resaltado} to="/nosotros">
            ¿Quiénes somos?
          </Link>
          <br />
          <Link className={styles.resaltado} to="/crearEvento">
            ¿Sos productor? Vende tu evento
          </Link>
          <br />
          <Link className={styles.resaltado} to="/signup-productor">
            Registrarme como productor
          </Link>
        </article>
        <article className={styles.linksYCertificados}>
          <ul>
          <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/faq">Preguntas frecuentes (FAQ)</Link>
            </li>
            <li>
              <Link to="/terminosYCondiciones">Términos y condiciones</Link>
            </li>
            <li>
              <Link to="/trabaja-con-nosotros">Unite al equipo</Link>
            </li>
          </ul>
          <div className={styles.contenedorCertificados}>
            <img
              src={
                "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/footer/certification-logo.svg"
              }
              alt="Certificado Google."
            />
            <img
              className={styles.mp}
              src={
                "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/footer/MercadoPago_(Horizontal).svg"
              }
              alt="Certificado Mercado Pago."
            />
            <img
              className={styles.ssl}
              src={
                "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/footer/shopify-ssl-secured.svg"
              }
              alt="Certificado SSL."
            />
          </div>
        </article>
      </footer>
      <div className={styles.rights}>
        © 2024 simplepass. Todos los derechos reservados.
      </div>
    </>
  );
};

export default Footer;
