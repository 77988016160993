import React, { useState, useEffect } from "react";
import styles from "./CrearEvento.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import LocationSearchInput from "../../Components/Buttons-loaders-inputs/InputBuscarUbicacion-GoogleMaps/LocationSearchInput";
import AutenticadorSimplepass from "../../Components/Autenticador/AutenticadorSimplepass";
import axios from "axios";
import Swal from "sweetalert2";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthProvider";
import variables from "../../Context/Variables";

const CrearEvento = ({ tipoPlan }) => {
  // Configuración específica para cada plan
  const config = {
    DIRECTO: {
      distribucionDePagos: "DIRECTO",
      titulo: "Crear evento: Plan directo",
      rutaRedirect: "/conectar-cuentas-mp/",
    },
    BASICO: {
      distribucionDePagos: "BASICO",
      titulo: "Crear evento: Plan básico",
      rutaRedirect: "/configuracion-finanzas/",
    },
  };

  // ESTADOS GENERALES
  const [isLoading, setIsLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [mayoresEdad, setMayoresEdad] = useState(false);
  const [ubicacion, setUbicacion] = useState("");
  const [aceptaTerminos, setAceptaTerminos] = useState(false);
  const [imagenEvento, setImagenEvento] = useState(null);
  const [organizacion, setOrganizacion] = useState("");
  const [duracionDias, setDuracionDias] = useState(1);
  const [diasEvento, setDiasEvento] = useState([{ datetime: "" }]);
  const [diasEventoValidos, setDiasEventoValidos] = useState([]);
  const [erroresDiasEvento, setErroresDiasEvento] = useState([]);
  const [tiposTickets, setTiposTickets] = useState([
    { nombre: "", precio: "", cantidadLimite: "" },
  ]);
  const [tiposTicketsValidos, setTiposTicketsValidos] = useState([
    { nombreValido: false, precioValido: false, cantidadLimiteValida: false },
  ]);
  const [erroresTiposTickets, setErroresTiposTickets] = useState([
    { nombre: "", precio: "", cantidadLimite: "" },
  ]);
  const [errores, setErrores] = useState({
    nombre: "",
    descripcion: "",
    ubicacion: "",
    tiposTickets: [{ nombre: "", precio: "", cantidadLimite: "" }],
    imagenEvento: "",
    aceptaTerminos: "",
  });
  const [nombreValido, setNombreValido] = useState(false);
  const [organizacionValida, setOrganizacionValida] = useState(false);
  const [descripcionValida, setDescripcionValida] = useState(false);
  const [ubicacionValida, setUbicacionValida] = useState(false);
  const [formularioValido, setFormularioValido] = useState(false);
  const [usuarioNecesitaAutenticacion, setUsuarioNecesitaAutenticacion] =
    useState(true);
  const [debeVerificarAutenticacion, setDebeVerificarAutenticacion] =
    useState(true);

  const navigate = useNavigate();
  const { estaAutenticado } = useAuth();

  // Efecto para inicializar el componente
  useEffect(() => {
    document.title = config[tipoPlan].titulo;
    ensureAuthenticated();
    window.scrollTo(0, 0);
  }, []);

  // Efecto para verificar el estado de autenticación del usuario
  useEffect(() => {
    let intervalId;

    if (debeVerificarAutenticacion) {
      intervalId = setInterval(() => {
        const userDetails =
          sessionStorage.getItem("userDetails") ||
          localStorage.getItem("userDetails");
        if (userDetails) {
          setUsuarioNecesitaAutenticacion(false);
          setDebeVerificarAutenticacion(false);
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [debeVerificarAutenticacion]);

  // Efectos para verificar el formulario
  useEffect(() => {
    verificarFormulario();
  }, [aceptaTerminos, estaAutenticado, nombreValido, descripcionValida, ubicacionValida, organizacionValida, diasEventoValidos, tiposTicketsValidos]);

  // Verifica si el usuario está autenticado
  const ensureAuthenticated = () => {
    const userDetails =
      sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    if (!userDetails) {
      setUsuarioNecesitaAutenticacion(true);
    } else {
      setUsuarioNecesitaAutenticacion(false);
    }
  };

  // Verifica si el formulario completo es válido
  const verificarFormulario = () => {
    const todosTicketsValidos = tiposTicketsValidos.every(
      (ticketValido) =>
        ticketValido.nombreValido &&
        ticketValido.precioValido &&
        ticketValido.cantidadLimiteValida
    );

    const todasFechasValidas = diasEvento.every((dia) => dia.datetime !== "");

    const esFormularioValido =
      diasEventoValidos.every((valido) => valido) &&
      todosTicketsValidos &&
      todasFechasValidas &&
      !usuarioNecesitaAutenticacion &&
      nombreValido &&
      aceptaTerminos &&
      descripcionValida &&
      ubicacionValida &&
      organizacionValida;

    setFormularioValido(esFormularioValido);
  };

  // Maneja el evento de tecla presionada para prevenir el submit con Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };


  // Funciones para manejar cambios en los inputs
  const handleNombreChange = (e) => {
    const { value } = e.target;
    const isValid = value.trim().length >= 10;
    setNombre(value);
    setErrores((prev) => ({
      ...prev,
      nombre: isValid ? "" : "El nombre debe tener al menos 10 caracteres.",
    }));
    setNombreValido(isValid);
  };

  // Funcion que sirve para calcular los bytes de un texto
  function calculateByteSize(string) {
    return new Blob([string]).size;
  }


  const handleDescripcionChange = (e) => {
    const { value } = e.target;
    const byteSize = calculateByteSize(value);
    const isValid = value.trim().length >= 20 && byteSize <= 1020;
    
    setDescripcion(value);

    if (byteSize > 1020) {
      setErrores((prev) => ({
        ...prev,
        descripcion: "La descripción supera el límite de espacio permitido.",
      }));
    } else {
      setErrores((prev) => ({
        ...prev,
        descripcion: isValid ? "" : "La descripción debe tener al menos 20 caracteres.",
      }));
    }

    setDescripcionValida(isValid && byteSize <= 1020);
  };

  const handleOrganizacionChange = (e) => {
    const { value } = e.target;
    const isValid = value.trim().length > 4;
    setOrganizacion(value);
    setErrores((prev) => ({
      ...prev,
      organizacion: isValid
        ? ""
        : "La organización debe tener más de 4 caracteres.",
    }));
    setOrganizacionValida(isValid);
  };

  const handleLocationSelect = (place) => {
    if (place && place.mapsUrl) {
      setUbicacion(`${place.address}/${place.mapsUrl}`);
      setErrores((prev) => ({
        ...prev,
        ubicacion: "",
      }));
      setUbicacionValida(true);
    } else {
      setErrores((prev) => ({
        ...prev,
        ubicacion: "Por favor, selecciona una ubicación válida.",
      }));
      setUbicacionValida(false);
    }
  };

  const handleMayoresEdadChange = (e) => {
    setMayoresEdad(e.target.checked);
  };

  const handleDuracionDiasChange = (e) => {
    const nuevaDuracion = Number(e.target.value);
    setDuracionDias(nuevaDuracion);

    let ultimaFechaValida = new Date();
    if (diasEvento.length > 0 && diasEvento[0].datetime) {
      const posibleFecha = new Date(diasEvento[0].datetime);
      if (!isNaN(posibleFecha.getTime())) {
        ultimaFechaValida = posibleFecha;
      }
    }

    if (nuevaDuracion < diasEvento.length) {
      setDiasEvento(diasEvento.slice(0, nuevaDuracion));
      return;
    }

    const nuevosDiasEvento = Array.from(
      { length: nuevaDuracion },
      (_, index) => {
        if (diasEvento[index] && diasEvento[index].datetime) {
          const fechaExistente = new Date(diasEvento[index].datetime);
          if (!isNaN(fechaExistente.getTime())) {
            return { datetime: diasEvento[index].datetime };
          }
        }

        let fechaAjustada = new Date(ultimaFechaValida);
        fechaAjustada.setDate(
          fechaAjustada.getDate() + (index - diasEvento.length + 1)
        ); // Asegura el incremento correcto desde el último día existente

        let datetimeAjustado = "";
        if (!isNaN(fechaAjustada.getTime())) {
          datetimeAjustado = fechaAjustada.toISOString().slice(0, 16);
        }
        return { datetime: datetimeAjustado };
      }
    );

    setDiasEvento(nuevosDiasEvento);
  };

  const handleDiaEventoChange = (index, valor) => {
    const nuevosDiasEvento = [...diasEvento];
    nuevosDiasEvento[index] = { datetime: valor };
    setDiasEvento(nuevosDiasEvento);

    let esValido = true;
    let mensajeError = "";
    const fechaHora = new Date(valor);
    const ahora = new Date();

    if (fechaHora < ahora) {
      esValido = false;
      mensajeError = "La fecha y hora deben ser a partir de hoy.";
    }

    if (index > 0) {
      const fechaHoraAnterior = new Date(nuevosDiasEvento[index - 1].datetime);
      if (fechaHora <= fechaHoraAnterior) {
        esValido = false;
        mensajeError = "La fecha y hora deben ser posteriores al día anterior.";
      }
    }

    const nuevosDiasEventoValidos = [...diasEventoValidos];
    nuevosDiasEventoValidos[index] = esValido;
    setDiasEventoValidos(nuevosDiasEventoValidos);

    const nuevosErroresDiasEvento = [...erroresDiasEvento];
    nuevosErroresDiasEvento[index] = { datetime: mensajeError };
    setErroresDiasEvento(nuevosErroresDiasEvento);
  };

  const handleNombreTicketChange = (index, valor) => {
    const nuevosTickets = [...tiposTickets];
    nuevosTickets[index].nombre = valor;
    setTiposTickets(nuevosTickets);

    const esNombreValido = valor.trim().length >= 3;
    const nuevosTicketsValidos = [...tiposTicketsValidos];
    nuevosTicketsValidos[index].nombreValido = esNombreValido;
    setTiposTicketsValidos(nuevosTicketsValidos);

    const nuevosErroresTiposTickets = [...erroresTiposTickets];
    nuevosErroresTiposTickets[index].nombre = esNombreValido
      ? ""
      : "El nombre debe tener al menos 3 caracteres.";
    setErroresTiposTickets(nuevosErroresTiposTickets);
  };

  const handlePrecioTicketChange = (index, valor) => {
    const nuevosTickets = [...tiposTickets];
    nuevosTickets[index].precio = valor;
    setTiposTickets(nuevosTickets);

    const esPrecioValido = valor > 0;
    const nuevosTicketsValidos = [...tiposTicketsValidos];
    nuevosTicketsValidos[index].precioValido = esPrecioValido;
    setTiposTicketsValidos(nuevosTicketsValidos);

    const nuevosErroresTiposTickets = [...erroresTiposTickets];
    nuevosErroresTiposTickets[index].precio = esPrecioValido
      ? ""
      : "El precio debe ser mayor a 0.";
    setErroresTiposTickets(nuevosErroresTiposTickets);
  };

  const handleCantidadLimiteTicketChange = (index, valor) => {
    const nuevosTickets = [...tiposTickets];
    nuevosTickets[index].cantidadLimite = valor;
    setTiposTickets(nuevosTickets);

    const esCantidadLimiteValida = valor > 0;
    const nuevosTicketsValidos = [...tiposTicketsValidos];
    nuevosTicketsValidos[index].cantidadLimiteValida = esCantidadLimiteValida;
    setTiposTicketsValidos(nuevosTicketsValidos);

    const nuevosErroresTiposTickets = [...erroresTiposTickets];
    nuevosErroresTiposTickets[index].cantidadLimite = esCantidadLimiteValida
      ? ""
      : "La cantidad límite debe ser mayor a 0.";
    setErroresTiposTickets(nuevosErroresTiposTickets);
  };

  const handleImagenEventoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Verifica si el tamaño del archivo excede 1.5 MB
      if (file.size > 1.5 * 1024 * 1024) {
        setErrores((prevErrores) => ({
          ...prevErrores,
          imagenEvento: "El archivo no debe exceder 1.5 MB.",
        }));

        e.target.value = ""; // Limpia el input de archivo si el tamaño excede el límite
      } else {
        setImagenEvento(file);
        setErrores((prevErrores) => ({
          ...prevErrores,
          imagenEvento: "",
        }));
      }
    } else {
      setImagenEvento(null);
      setErrores((prevErrores) => ({
        ...prevErrores,
        imagenEvento: "No se ha seleccionado ningún archivo.",
      }));
    }
  };

  const handleAceptaTerminosChange = (e) => {
    const { checked } = e.target;
    setAceptaTerminos(checked);
    setErrores((prevErrores) => ({
      ...prevErrores,
      aceptaTerminos: checked
        ? ""
        : "Debes aceptar los términos y condiciones para continuar.",
    }));
  };

  const agregarTipoTicket = () => {
    setTiposTickets((prev) => [
      ...prev,
      { nombre: "", precio: "", cantidadLimite: "" },
    ]);

    setTiposTicketsValidos((prev) => [
      ...prev,
      { nombreValido: false, precioValido: false, cantidadLimiteValida: false },
    ]);

    setErroresTiposTickets((prev) => [
      ...prev,
      { nombre: "", precio: "", cantidadLimite: "" },
    ]);
  };

  const eliminarTipoTicket = (index) => {
    setTiposTickets((prev) => prev.filter((_, i) => i !== index));
    setTiposTicketsValidos((prev) => prev.filter((_, i) => i !== index));
    setErroresTiposTickets((prev) => prev.filter((_, i) => i !== index));
  };

  const renderDiasEventoInputs = () => {
    const ahora = new Date().toISOString().slice(0, 16); // Obtener la fecha y hora actuales en el formato adecuado

    return diasEvento.map((dia, index) => (
      <div className={styles.contenedorInfo} key={index}>
        <h4 className={styles.subtitulo}>
          Día {index + 1} - Fecha y hora de inicio:
        </h4>
        <input
          type="datetime-local"
          value={dia.datetime}
          min={ahora} // Establecer el valor mínimo permitido
          onChange={(e) => handleDiaEventoChange(index, e.target.value)}
        />
        {erroresDiasEvento[index]?.datetime && (
          <p className={styles.error}>{erroresDiasEvento[index].datetime}</p>
        )}
      </div>
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userDetails =
      sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    if (!userDetails) {
      setIsLoading(false);
      return;
    }

    const details = JSON.parse(userDetails);
    const token = details.jwt;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("nombreEvento", nombre);
    formData.append("descripcion", descripcion);
    formData.append("esParaMayores", mayoresEdad);
    formData.append("ubicacion", ubicacion);
    formData.append("productor", organizacion);
    formData.append("distribucionDePagos", config[tipoPlan].distribucionDePagos);

    if (imagenEvento) formData.append("imagen", imagenEvento);

    const tiposDeTicketsJson = JSON.stringify(tiposTickets);
    formData.append("tiposDeTickets", tiposDeTicketsJson);

    diasEvento.forEach((dia) => {
      formData.append("fechasYHoras", dia.datetime);
    });

    try {
      const response = await axios.post(
        `${variables.API_BASE_URL}/api/eventos/agregarEvento`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        const errorMessage =
          response.data ||
          "La operación no fue exitosa. Por favor, intente de nuevo.";
        console.log("MENSAJE DEL BACK: " + errorMessage);
        Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        const eventoId = response.data.id;

        Swal.fire({
          title: "¡Éxito!",
          text: "Evento creado con éxito",
          icon: "success",
          confirmButtonText: "Ok",
          timer: 4000,
          willClose: () => {
            navigate(`${config[tipoPlan].rutaRedirect}${eventoId}`);
          },
        });

        setTimeout(() => {
          navigate(`${config[tipoPlan].rutaRedirect}${eventoId}`);
        }, 4000);
      }
    } catch (error) {
      let errorMessage = "No se pudo crear el evento. Intente nuevamente.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data;
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.bodyCrearEvento}>
      <section className={styles.eventoFoto}>
        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/search.png" alt="Evento" />
      </section>

      <div className={styles.contForm}>
        <h2>{config[tipoPlan].titulo}</h2>
        <h4 className={styles.subtitulo}>
          Como siguiente paso completa los campos con la información de tu evento
        </h4>
        <form onKeyDown={handleKeyDown} onSubmit={handleSubmit}>
          {/* Nombre del evento */}
          <div className={styles.contenedorInfo}>
            <label>Nombre del evento:</label>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre del Evento"
              value={nombre}
              onChange={handleNombreChange}
              className={errores.nombre ? styles.borderInvalid : styles.borderValid}
            />
            {errores.nombre && <p className={styles.error}>{errores.nombre}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Descripción */}
          <div className={styles.contenedorInfo}>
            <label>Descripción:</label>
            <textarea
              name="descripcion"
              placeholder="Descripción del Evento"
              value={descripcion}
              maxLength={255}
              onChange={handleDescripcionChange}
              className={errores.descripcion ? styles.borderInvalid : styles.borderValid}
            />

            {errores.descripcion && <p className={styles.error}>{errores.descripcion}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Organización */}
          <div className={styles.contenedorInfo}>
            <label>Organización o productor del evento:</label>
            <input
              type="text"
              name="organizacion"
              placeholder="Nombre de la organización o productor"
              value={organizacion}
              onChange={handleOrganizacionChange}
              className={errores.organizacion ? styles.borderInvalid : styles.borderValid}
            />
            {errores.organizacion && <p className={styles.error}>{errores.organizacion}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Mayoría de edad */}
          <h3 className={styles.subtitulo}>¿Es para mayores de edad?:</h3>
          <div className={`${styles.containerCheckbox} ${styles.mayoresCont} ${styles.contenedorInfo}`}>
            <label>
              <input
                id="mayoresEdad"
                type="checkbox"
                name="mayoresEdad"
                checked={mayoresEdad}
                onChange={handleMayoresEdadChange}
              />
              <svg viewBox="0 0 64 64" height="2em" width="2em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  className={styles.path}
                ></path>
              </svg>
              <span>Sí, es para mayores de 18.</span>
            </label>
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Ubicación */}
          <div className={styles.contenedorInfo}>
            <label>
              Ubicación <small>(Busca la dirección exacta de tu evento)</small>:
            </label>
            <LocationSearchInput onLocationSelect={handleLocationSelect} />
            {errores.ubicacion && <p className={styles.error}>{errores.ubicacion}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Duración del evento y fechas */}
          <div className={styles.contenedorInfo}>
            <label>¿Cuántos días dura tu evento?</label>
            <input
              type="number"
              value={duracionDias}
              onChange={handleDuracionDiasChange}
              min="1"
              max="10"
              className={styles.inputNumber}
            />
          </div>
          {renderDiasEventoInputs()}

          <section className={styles.lineaDivisora}></section>

          {/* Tipos de tickets */}
          <h3 className={styles.subtitulo}>Tipos de tickets a vender:</h3>
          {tiposTickets.map((tipo, index) => (
            <div className={`${styles.tipoDeTicket} ${styles.contenedorInfo}`} key={index}>
              <p className={styles.numeroTipoTicket}>
                Tipo de ticket: {index + 1}
              </p>

              <label className={styles.nombreLabel}>Nombre tipo ticket:</label>
              <input
                type="text"
                placeholder='Ejemplo: "General primer tanda"'
                value={tipo.nombre}
                onChange={(e) => handleNombreTicketChange(index, e.target.value)}
                className={
                  erroresTiposTickets[index] && erroresTiposTickets[index].nombre
                    ? styles.borderInvalid
                    : styles.borderValid
                }
              />
              {erroresTiposTickets[index] && erroresTiposTickets[index].nombre && (
                <p className={styles.error}>
                  {erroresTiposTickets[index].nombre}
                </p>
              )}

              <label>
                Precio<small>(sin signo $)</small>:
              </label>
              <input
                type="number"
                placeholder="Ejemplo: 5000"
                value={tipo.precio}
                onChange={(e) => handlePrecioTicketChange(index, e.target.value)}
                className={
                  erroresTiposTickets[index] && erroresTiposTickets[index].precio
                    ? styles.borderInvalid
                    : styles.borderValid
                }
              />
              {erroresTiposTickets[index] && erroresTiposTickets[index].precio && (
                <p className={styles.error}>
                  {erroresTiposTickets[index].precio}
                </p>
              )}

              <label>Cantidad máxima de venta:</label>
              <input
                type="number"
                placeholder="Cantidad Límite"
                value={tipo.cantidadLimite}
                onChange={(e) => handleCantidadLimiteTicketChange(index, e.target.value)}
                className={
                  erroresTiposTickets[index] && erroresTiposTickets[index].cantidadLimite
                    ? styles.borderInvalid
                    : styles.borderValid
                }
              />
              {erroresTiposTickets[index] && erroresTiposTickets[index].cantidadLimite && (
                <p className={styles.error}>
                  {erroresTiposTickets[index].cantidadLimite}
                </p>
              )}

              <button
                className={styles.eliminarBtn}
                type="button"
                onClick={() => eliminarTipoTicket(index)}
              >
                Eliminar tipo de ticket <FontAwesomeIcon icon={faMinus} />
              </button>
            </div>
          ))}
          <button
            className={styles.agregarBtn}
            type="button"
            onClick={agregarTipoTicket}
          >
            Agregar tipo de ticket <FontAwesomeIcon icon={faPlus} />
          </button>

          <section className={styles.lineaDivisora}></section>

          {/* Carga de imagen */}
          <div className={styles.contenedorInfo}>
            <label>Imagen del evento (podés agregarla luego):</label>
            <small>Por favor suba una imagen con dimensiones cuadradas (misma altura y ancho).</small>
            <input
              type="file"
              name="imagenEvento"
              onChange={handleImagenEventoChange}
            />
            {errores.imagenEvento && <p className={styles.error}>{errores.imagenEvento}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Autenticación */}
          <div id="avisoAutenticacion"
            className={`${styles.avisoAutenticacion} ${usuarioNecesitaAutenticacion ? styles.avisoAutenticacionVisible : ''}`}>
            <AutenticadorSimplepass
              titulo="Para gestionar tu evento, crea una cuenta o ingresa con la tuya de forma rápida y sencilla:"
              rol="PRODUCTOR"
            />
          </div>

          {/* Aceptar términos y condiciones */}
          <div className={styles.containerCheckbox}>
            <label className={styles.container}>
              <input
                type="checkbox"
                checked={aceptaTerminos}
                onChange={handleAceptaTerminosChange}
              />
              <svg viewBox="0 0 64 64" height="2em" width="2em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  className={styles.path}
                ></path>
              </svg>
              <span>
                He leído y acepto los{" "}
                <a
                  href="URL_A_TUS_TERMINOS_Y_CONDICIONES"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.termsLink}
                >
                  términos y condiciones
                </a>{" "}
                de crear un evento con {tipoPlan === "directo" ? "plan directo" : "plan básico"} de Simplepass.
              </span>
            </label>
            {errores.aceptaTerminos && (
              <p className={styles.error}>{errores.aceptaTerminos}</p>
            )}
          </div>

          {/* Botón de envío */}
          <div className={styles.contSubmit}>
            <SubmitButton
              isDisabled={!formularioValido}
              color="#99419d"
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Crear evento
            </SubmitButton>
          </div>
        </form>
        <a
          className={styles.btWhatsapp}
          href="https://wa.me/+543436114446"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
    </div>
  );
};

export default CrearEvento;