import React, { useState } from 'react';
import axios from 'axios';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import styles from "./TrabajaConNosotros.module.scss";
import variables from "../../Context/Variables";
import Swal from 'sweetalert2';

const TrabajaConNosotros = () => {
  const [nombreCompleto, setNombreCompleto] = useState('');
  const [email, setEmail] = useState('');
  const [archivo, setArchivo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validarNombre = (nombre) => {
    return nombre.length >= 4 && nombre.length <= 30;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validarNombre(nombreCompleto)) {
      Swal.fire({
        icon: 'error',
        title: 'Nombre Inválido',
        text: 'El nombre debe tener entre 4 y 30 caracteres.'
      });
      return;
    }

    if (archivo && archivo.size > 1.5 * 1024 * 1024) {
      Swal.fire({
        icon: 'error',
        title: 'Archivo muy grande',
        text: 'El archivo no debe superar los 1.5MB.'
      });
      return;
    }

    const formData = new FormData();
    formData.append('nombreCompleto', nombreCompleto);
    formData.append('email', email);
    formData.append('archivo', archivo);

    setIsLoading(true);

    try {
      const response = await axios.post(`${variables.API_BASE_URL}/api/empleo/enviar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '¡Enviado!',
          text: 'Tu solicitud ha sido enviada correctamente.'
        });
      } else {
        throw new Error('No se pudo enviar la solicitud.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al enviar la solicitud: ' + (error.response?.data || error.message)
      });
      console.error('Error al enviar la solicitud:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.trabajaConNosotros}>
      <h1>¡Trabaja con nosotros! te estamos buscando.</h1>
      <p>Sumate a la mision de crear la mejor ticketera del país 🚀</p>
      <h2>Puestos buscados</h2>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>Desarrollador full stack semi-senior</div>
        <div className={styles.card}>Desarrollador Front-End semi-senior</div>
        <div className={styles.card}>Trafficker digital</div>
        <div className={styles.card}>Diseñador digital</div>
      </div>
      <div className={styles.contEmail}>
        <h3>Aplicar a:</h3>
        <h4>empleos@simplesistemas.com</h4>
      </div>

    </div>
  );
};

export default TrabajaConNosotros;
