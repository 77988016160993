import React, { useState, useMemo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import styles from './Map.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight,faMapPin } from '@fortawesome/free-solid-svg-icons';

const MapComponent = ({ data }) => {

    const [url, setUrl] = useState("");

    const isValidHttpUrl = (string) => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    const parseLocation = (dataString) => {
        const parts = dataString.split('/');
        const name = parts.shift();
        const url = parts.join('/');
        setUrl(url);

        if (!isValidHttpUrl(url)) {
            console.error('URL inválida:', url);
            return { name, location: { lat: 0, lng: 0 } };
        }

        const query = new URL(url).search;
        const params = new URLSearchParams(query);
        const [lat, lng] = params.get('query').split(',');
        return { name, location: { lat: parseFloat(lat), lng: parseFloat(lng) } };
    };

    const location = useMemo(() => parseLocation(data), [data]);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "YOUR_GOOGLE_MAPS_API_KEY"
    });

    if (loadError) {
        return <div className={styles.error}>Error loading maps</div>;
    }

    return (
        <div className={styles.mapContainer}>
            <h2 className={styles.title}>¿Cómo llegar?</h2>
            <div className={styles.mapHolder}>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={location.location}
                        zoom={15}
                    >
                        <Marker position={location.location} />
                    </GoogleMap>
                ) : <div className={styles.loading}>Cargando mapa...</div>}
            </div>
            <a className={styles.verEnGoogle} target="_blank" href={url}>
                Abrir en <img
                    src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/Google_Maps.svg.png"
                    alt="Google Maps"
                    className={styles.googleMapsIcon}
                /> <FontAwesomeIcon icon={faAngleRight} />
            </a>
        </div>

    );
}

export default MapComponent;

