import React from 'react';
import styles from './Asistencia.module.scss';
import EmailButton from '../../../Components/Buttons-loaders-inputs/EmailButton/EmailButton';
import WhatsAppButton from '../../../Components/Buttons-loaders-inputs/WhatsAppButton/WhatsAppButton ';

const Asistencia = () => {
    return (
        <div className={styles.container}>
            <div className={styles.info}>
                <h2 className={styles.title}>Asistencia personalizada para tu evento</h2>
                <p className={styles.paragraph}>
                    Contactanos para recibir asistencia personalizada y resolver todas tus inquietudes.
                </p>
                <p className={styles.paragraph}>
                    Nuestro equipo está acá para ayudarte en cada paso del camino, asegurando que tu evento sea un éxito.
                </p>
            </div>
            <div className={styles.buttons}>
                <EmailButton
                    emailAddress="simplesistemasweb@gmail.com"
                    subject="Información sobre como crear mi evento"
                    body="Hola, me gustaría recibir más información sobre la asistencia para eventos."
                    className={styles.emailButton}
                />
                <WhatsAppButton
                    phoneNumber="+5493436114446"
                    message="Hola, estoy interesado en crear mi evento. ¿Podrían darme más información?"
                    className={styles.chatButton}
                />
            </div>
        </div>
    );
};

export default Asistencia;