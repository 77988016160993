import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from "./SignUpForm.module.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthProvider";
import { GoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import SubmitButton from "../../Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../../Context/Variables";
import PhoneInputComponent from "../../PhoneInputComponent/PhoneInputComponent";
import { isValidPhoneNumber } from 'react-phone-number-input';

const SignUpForm = ({ rol }) => {
  // Definir initialState dentro del componente
  const initialState = {
    nombre: "",
    email: "",
    dni: "",
    celular: "",
    contraseña: "",
    contraseñaConfirmada: "",
    terminos: false,
  };

  //Estado para almacenar el rol
  const [rolDado, setRolDado] = useState(rol);

  //Estados para manejar errores y registro con google
  const { login } = useAuth();
  const [error, setError] = useState("");

  // Estado para almacenar los datos del formulario
  const [formData, setFormData] = useState(initialState);

  // Estado para manejar la visibilidad de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Estado para el boton submit
  const [buttonColor, setButtonColor] = useState("#b2b2b2");

  // Estado para el botón submit (añadido)
  const [isLoading, setIsLoading] = useState(false);

  // Estado para almacenar si el formulario es válido
  const [isFormValid, setIsFormValid] = useState(false);

  // Estado para almacenar los errores de validación
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    // Validar que todos los campos están llenos (excepto errores que es un objeto y no un campo de formulario)
    const allFieldsFilled = Object.values(formData).every((value) => {
      if (typeof value === "boolean") return true; // Para checkboxes como 'terminos'
      return value !== "";
    });

    // Validar campos específicos con sus funciones de validación
    const nombreValido = validarNombreCompleto(formData.nombre);
    const emailValido = validarEmail(formData.email);
    const dniValido = validarDNI(formData.dni);
    const celularValido = validarCelular(formData.celular);
    const contraseñaValida = validarContraseña(formData.contraseña);
    const contraseñasCoinciden =
      formData.contraseña === formData.contraseñaConfirmada;
    const terminosAceptados = formData.terminos; // Asegúrate de que el checkbox de términos está marcado

    // Comprobar que todos los campos son válidos
    const allFieldsValid =
      nombreValido &&
      emailValido &&
      dniValido &&
      celularValido &&
      contraseñaValida &&
      contraseñasCoinciden &&
      terminosAceptados;

    // Actualizar el estado de validez del formulario
    setIsFormValid(allFieldsValid);
  };

  // Observar cambios en formData y errors para validar el formulario
  useEffect(() => {
    validateForm();
  }, [formData, errors]);

  // Lógica para determinar si el botón debe estar deshabilitado
  const isButtonDisabled = !isFormValid;

  // Manejador de cambios en los campos del formulario
  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    if (type === "checkbox") {
      console.log(newValue);
    }

    // Actualizar el estado del formulario
    setFormData((prev) => ({ ...prev, [id]: newValue }));

    // Validar el campo actualizado
    const errorMsg = validateField(id, newValue);

    // Aplicar estilos de borde basados en la validación
    if (errorMsg) {
      e.target.style.borderColor = "red";
    } else if (newValue !== "") {
      // Solo aplicar borde verde si el campo no está vacío
      e.target.style.borderColor = "green";
    } else {
      e.target.style.removeProperty("border-color"); // O e.target.style.borderColor = '';
    }

    // Iniciar la validación específica para el campo que cambió
    validateField(id, newValue);
  };

  // Validar cada campo del formulario
  const validateField = (id, value) => {
    let errorMsg = "";

    switch (id) {
      case "nombre":
        if (!validarNombreCompleto(value)) {
          errorMsg = "El nombre debe tener al menos 4 caracteres.";
        }
        break;
      case "email":
        if (!validarEmail(value)) {
          errorMsg = "Ingresa un email válido.";
        }
        break;
      case "dni":
        if (!validarDNI(value)) {
          errorMsg = "El DNI/Pasaporte debe tener entre 7 y 15 dígitos.";
        }
        break;
      case "celular":
        if (!isValidPhoneNumber(value)) {
          errorMsg = "Debes ingresar un número de teléfono válido.";
        }
        break;
      case "contraseña":
        if (!validarContraseña(value)) {
          errorMsg =
            "La contraseña debe tener al menos 6 caracteres, incluir una mayúscula y una minúscula.";
        }
        break;
      case "contraseñaConfirmada":
        if (formData.contraseña !== value) {
          errorMsg = "Las contraseñas no coinciden.";
        }
        break;
      default:
        break;
    }

    // Establecer mensajes de error en el estado si es necesario
    setErrors((prev) => ({ ...prev, [id]: errorMsg }));

    // Devolver el mensaje de error para uso inmediato
    return errorMsg;
  };

  const handlePhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, celular: phone }));

    const errorMsg = validateField("celular", phone);

    if (errorMsg) {
      setErrors((prev) => ({ ...prev, celular: errorMsg }));
    } else {
      setErrors((prev) => ({ ...prev, celular: "" }));
    }
  };

  // Función para alternar la visibilidad de la contraseña
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Función para alternar la visibilidad de la confirmación de contraseña
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Funciones de validación de campos
  const validarCelular = (celular) => {
    const regexCelular = /^(?:\+?549?)?([0-9]{10})$/;
    return regexCelular.test(celular);
  };

  const validarNombreCompleto = (nombre) => {
    return nombre.trim().length >= 4;
  };

  const validarDNI = (dni) => {
    const regexDNI = /^\d{7,15}$/;
    return regexDNI.test(dni);
  };

  const validarContraseña = (contraseña) => {
    const regexContraseña = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return regexContraseña.test(contraseña);
  };

  const validarEmail = (email) => {
    const regexEmail = /\S+@\S+\.\S+/;
    return regexEmail.test(email);
  };

  // Manejador del envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {};

    // Validaciones
    if (!validarNombreCompleto(formData.nombre)) {
      newErrors.nombre = "El nombre debe tener al menos 4 caracteres.";
    }
    if (!validarEmail(formData.email)) {
      newErrors.email = "Ingresa un email válido.";
    }
    if (!validarDNI(formData.dni)) {
      newErrors.dni = "El DNI/Pasaporte debe tener entre 7 y 15 dígitos.";
    }
    if (!validarCelular(formData.celular)) {
      newErrors.celular =
        "Debes ingresar un celular válido con código de área y sin 15.";
    }
    if (!validarContraseña(formData.contraseña)) {
      newErrors.contraseña =
        "La contraseña debe tener al menos 6 caracteres, incluir una mayúscula y una minúscula.";
    }
    if (formData.contraseña !== formData.contraseñaConfirmada) {
      newErrors.contraseñaConfirmada = "Las contraseñas no coinciden.";
    }
    if (!formData.terminos) {
      newErrors.terminos = "Debes aceptar los términos y condiciones.";
    }

    setIsLoading(true); // Iniciar la carga

    setErrors(newErrors);

    // Si no hay errores en el formulario, proceder con el envío de datos
    if (Object.keys(newErrors).length === 0) {
      try {
        console.log(formData);
        
        const datosParaEnviar = {
          username: formData.nombre, 
          password: formData.contraseña,
          email: formData.email,
          dni: formData.dni,
          celular: formData.celular,
        };

        console.log(rol);
        let response;
        if (rolDado === "PRODUCTOR") {
          console.log("Se verifico desde SIGNUPFORM que pasamos PRODUCTOR");
          response = await axios.post(
            variables.API_BASE_URL + "/usuarios/registrarProductorYLoguearlo",
            datosParaEnviar);
        } else {
          console.log(rol);
          response = await axios.post(
            variables.API_BASE_URL + "/usuarios/registrarUsuarioYLoguearlo",
            datosParaEnviar
          );
        }

        // Mostrar mensaje de éxito
        Swal.fire({
          icon: "success",
          text: "Tu cuenta ha sido creada correctamente.",
          timer: 3000,
        });

        // Aquí asumimos que la API devuelve un objeto con un jwt en su cuerpo de respuesta
        const jwt = response.data.jwt; // Asegúrate de que esta ruta corresponde a cómo tu backend envía el JWT

        if (jwt) {
          login(response.data, false); // Autenticar al usuario en el cliente
          // Opcional: Disparar un evento personalizado si tienes otros componentes que necesitan saber sobre la autenticación
          const event = new CustomEvent("userAuthenticated", { detail: jwt });
          window.dispatchEvent(event);
        } else {
          // Manejar caso en que no se recibe un JWT como se esperaba
          console.error("No se recibió un token JWT válido.");
        }

        setFormData(initialState); // Limpiar el formulario
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Mostrar mensaje de error devuelto por la API
          Swal.fire({
            icon: "error",
            text: error.response.data,
            timer: 4000,
          });
        } else {
          // Manejar otros errores posibles
          Swal.fire({
            icon: "error",
            text:
              "Hubo un problema con la solicitud: " +
              (error.response ? error.response.data.message : error.message),
            timer: 4000,
          });
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      // Mostrar mensaje indicando que el formulario tiene errores
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor, completa el formulario correctamente antes de enviar.",
      });
    }
  };

  function handleError() {
    setError(
      "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
    );
  }

  const handleGoogleLogin = async (googleData) => {
    console.log(rol);
    let apiUrl;
    if (rolDado === "PRODUCTOR") {
      console.log("Se verifico desde SIGNUPFORM que pasamos PRODUCTOR");
      apiUrl = variables.API_BASE_URL + "/usuarios/authGoogleWithUser/PRODUCTOR";
    } else {
      apiUrl = variables.API_BASE_URL + "/usuarios/authGoogleWithUser/USER";
    }

    try {
      // Enviar el token al backend
      const response = await axios.post(apiUrl, {
        tokenId: googleData.credential, // Asegúrate de enviar el dato correcto según tu implementación
      });

      // Si el backend valida el token y encuentra/crea el usuario correctamente
      if (response.data && response.data.jwt) {
        login(response.data, false);
        Swal.fire({
          icon: "success",
          text: "Has ingresado con Google exitosamente. Podés continuar.",
          timer: 5000,
        });
        const event = new CustomEvent("userAuthenticated", {
          detail: response.data.jwt,
        });
        window.dispatchEvent(event);
      } else {
        // Maneja casos donde la respuesta no es la esperada
        setError(
          "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
        );
      }
    } catch (error) {
      // Manejar errores de la petición
      console.error("Error al iniciar sesión con Google:", error);
      setError(
        "Error al iniciar sesión con Google. Por favor, inténtalo de nuevo."
      );
    }
  };

  //Verificar que el fomulario sea valido completamente para asi habilitar boton
  useEffect(() => {
    // Verificar si algún campo está vacío
    const isAnyFieldEmpty = Object.values(formData).some(
      (value) => value === ""
    );

    // Verificar si todos los campos son válidos
    const formIsValid = Object.values(errors).every((error) => error === "");

    // Cambiar el color del botón según el estado del formulario
    setButtonColor(formIsValid && !isAnyFieldEmpty ? "#252525" : "#b2b2b2");
  }, [formData, errors]);

  return (
    <div
      className={`${styles.loginForm} ${styles.parteFormLogin} ${styles.productorForm}`}
    >
      <form
        id="agregar-simpleUser"
        className={styles.nright}
        onSubmit={handleSubmit}
      >
        <h3>¡Creá tu cuenta!</h3>
        <h4>Este es el primer paso para disfrutar tus eventos favoritos.</h4>
        {error && <div className={styles.errorMesagge}>{error}</div>}

        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={handleError}
          className={styles.google}
        />

        <small className={styles.o}>o</small>

        <h5>Nombre completo</h5>
        <input
          type="text"
          id="nombre"
          placeholder="Ingresa tu nombre completo"
          value={formData.nombre}
          onChange={handleChange}
        />
        {errors.nombre && <p className={styles.error}>{errors.nombre}</p>}

        <h5>Email</h5>
        <input
          type="email"
          id="email"
          placeholder="ejemplo@mail.com"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className={styles.error}>{errors.email}</p>}

        <h5>DNI/Pasaporte</h5>
        <input
          type="text"
          id="dni"
          placeholder="Ingresa tu DNI sin puntos"
          value={formData.dni}
          onChange={handleChange}
        />
        {errors.dni && <p className={styles.error}>{errors.dni}</p>}

        <h5>Celular</h5>
        <PhoneInputComponent
          value={formData.celular}
          onChange={handlePhoneChange}
        />
        {errors.celular && <p style={{ margin: "0" }} className={styles.error}>{errors.celular}</p>}

        <h5>Contraseña</h5>
        <div className={styles.inputGroup}>
          <input
            type={showPassword ? "text" : "password"}
            id="contraseña"
            placeholder="Ingresa una contraseña"
            className={styles.passwordInput}
            value={formData.contraseña}
            onChange={handleChange}
          />
          <button
            type="button"
            className={styles.togglePassword}
            onClick={togglePasswordVisibility}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>
        {errors.contraseña && (
          <p className={styles.error}>{errors.contraseña}</p>
        )}

        <h5>Confirma tu contraseña</h5>
        <div className={styles.inputGroup}>
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="contraseñaConfirmada"
            placeholder="Reingresa tu contraseña"
            className={styles.passwordInput}
            value={formData.contraseñaConfirmada}
            onChange={handleChange}
            onPaste={(e) => e.preventDefault()}
          />
          <button
            type="button"
            className={styles.togglePassword}
            onClick={toggleConfirmPasswordVisibility}
          >
            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
          </button>
        </div>
        {errors.contraseñaConfirmada && (
          <p className={styles.error}>{errors.contraseñaConfirmada}</p>
        )}

        <div className={styles.containerCheckbox}>
          <input
            type="checkbox"
            id="terminos"
            checked={formData.terminos}
            onChange={handleChange}
          />
          <label htmlFor="terminos" className={styles.checkboxLabel}>
            <svg viewBox="0 0 64 64" height="1em" width="1em">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                className={styles.path}
              ></path>
            </svg>
            <p>
              He leído y acepto los{" "}
              <Link target="_blank" rel="noopener noreferrer" to={"/terminosYCondiciones"}>términos y condiciones.</Link>
            </p>
          </label>
        </div>

        {errors.terminos && <p className={styles.error}>{errors.terminos}</p>}

        <div id="resultado"></div>

        <div className={styles.contSubmit}>
          <SubmitButton
            isDisabled={isButtonDisabled}
            color="#99419d" 
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Crear mi cuenta
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
