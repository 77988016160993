import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import styles from "./CopyableLink.module.scss";

const CopyableLink = ({ link }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
            alert('Enlace copiado al portapapeles'); // O puedes usar una notificación más elegante si prefieres
        }, () => {
            alert('Error al copiar el enlace');
        });
    };

    return (
        <div className={styles.copyableLinkContainer}>
            <input type="text" value={link} readOnly className={styles.copyableLinkInput} />
            <button onClick={copyToClipboard} className={styles.copyButton}>
                <FontAwesomeIcon icon={faCopy} /> Copiar
            </button>
        </div>
    );
};

export default CopyableLink;
