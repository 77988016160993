import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import variables from '../../Context/Variables';
import styles from './GestionMesas.module.scss';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import MesaSkeleton from './Skeleton/MesaSkeleton';

const ExampleModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <span className={styles.closeButton} onClick={onClose}>&times;</span>
                <p style={{padding:"8px", fontSize:"14px"}}>Este es un ejemplo de cómo verán los consumidores la sección de compra de mesas.</p>
                <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/gestion+mesas/ejemplo.png" alt="Ejemplo de imagen" className={styles.exampleImage} />
            </div>
        </div>
    );
};

const GestionMesas = () => {
    const { jwt, estaAutenticado } = useAuth();
    const { eventoId } = useParams();
    const [mesas, setMesas] = useState([]);
    const [erroresMesas, setErroresMesas] = useState([]);
    const [imagenMesas, setImagenMesas] = useState(null);
    const [urlImagenMesas, setUrlImagenMesas] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasPermission, setHasPermission] = useState(true);
    const [cargandoMesas, setCargandoMesas] = useState(false);
    const [errorMesaDescripcion, setErrorMesaDescripcion] = useState(null)
    const [descripcionMesas, setDescripcionMesas] = useState('');
    const [isExampleModalOpen, setIsExampleModalOpen] = useState(false);

    const [newMesas, setNewMesas] = useState([]);
    const [modifiedMesas, setModifiedMesas] = useState([]);
    const [deletedMesas, setDeletedMesas] = useState([]);

    const calculateByteSize = (str) => new Blob([str]).size;

    const toggleExampleModal = () => {
        setIsExampleModalOpen(!isExampleModalOpen);
    };

    useEffect(() => {
        if (!estaAutenticado || !jwt) {
            setHasPermission(false);
        } else {
            fetchMesas();
        }
    }, [estaAutenticado, jwt]);

    useEffect(() => {
        document.title = "Administrar venta de mesas";
        window.scrollTo(0, 0);
    }, []);

    const fetchMesas = async () => {
        try {
            setCargandoMesas(true);
            const response = await axios.get(`${variables.API_BASE_URL}/api/tdt/evento/${eventoId}/mesas`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                const { mesas, imagenUrl, descripcionMesas } = response.data;
                setMesas(mesas);
                setUrlImagenMesas(imagenUrl);
                setDescripcionMesas(descripcionMesas || '');
                setErroresMesas(mesas.map(() => ({ nombre: '', precio: '', estaDisponible: '' })));
            } else {
                setHasPermission(false);
            }
        } catch (error) {
            console.error('Error fetching mesas:', error);
        } finally {
            setCargandoMesas(false);
        }
    };

    const handleInputChange = (index, valor, campo) => {
        const updatedMesas = [...mesas];
        updatedMesas[index] = { ...updatedMesas[index], [campo]: valor };

        const updatedErrors = [...erroresMesas];
        updatedErrors[index][campo] = validarCampo(valor, campo) ? '' : `El campo ${campo} es inválido.`;

        setMesas(updatedMesas);
        setErroresMesas(updatedErrors);

        if (!updatedMesas[index].id || updatedMesas[index].id.startsWith("new-")) {
            const newIdx = newMesas.findIndex(mesa => mesa.id === updatedMesas[index].id);
            if (newIdx === -1) {
                setNewMesas([...newMesas, updatedMesas[index]]);
            } else {
                const updatedNewMesas = [...newMesas];
                updatedNewMesas[newIdx] = updatedMesas[index];
                setNewMesas(updatedNewMesas);
            }
        } else if (updatedMesas[index].cantidadVendida === 0 || updatedMesas[index].estaDisponible) {
            const modIdx = modifiedMesas.findIndex(mesa => mesa.id === updatedMesas[index].id);
            if (modIdx === -1) {
                setModifiedMesas([...modifiedMesas, updatedMesas[index]]);
            } else {
                const updatedModifiedMesas = [...modifiedMesas];
                updatedModifiedMesas[modIdx] = updatedMesas[index];
                setModifiedMesas(updatedModifiedMesas);
            }
        }
    };

    const handleDisponibilidadChange = (index) => {
        const updatedMesas = [...mesas];
        updatedMesas[index].estaDisponible = !updatedMesas[index].estaDisponible;
        setMesas(updatedMesas);

        const modIdx = modifiedMesas.findIndex(mesa => mesa.id === updatedMesas[index].id);
        if (modIdx === -1) {
            setModifiedMesas([...modifiedMesas, updatedMesas[index]]);
        } else {
            const updatedModifiedMesas = [...modifiedMesas];
            updatedModifiedMesas[modIdx] = updatedMesas[index];
            setModifiedMesas(updatedModifiedMesas);
        }
    };

    if (!hasPermission) {
        return <NoPermissionPage />;
    }

    const validarCampo = (value, type) => {
        switch (type) {
            case 'nombre':
                return value.trim().length >= 2;
            case 'precio':
                return !isNaN(value) && Number(value) > 0;
            default:
                return false;
        }
    };

    const handleDescripcionChange = (e) => {
        const { value } = e.target;
        const byteSize = calculateByteSize(value); 
        const isValid = value.trim().length >= 20 && value.length <= 255; 
    
        setDescripcionMesas(value); 
    
        if (value.length > 255) {
            setErrorMesaDescripcion("La descripción no puede exceder los 255 caracteres.")
        } else if (byteSize > 255) {
            setErrorMesaDescripcion("La descripción supera el límite de espacio permitido (255 bytes).")
        } else if (value.trim().length < 20) {
            setErrorMesaDescripcion("La descripción debe tener al menos 20 caracteres.")
        } else {
            setErrorMesaDescripcion(null)
        }
    };

    const agregarMesa = () => {
        const nuevaMesa = { id: "new-" + new Date().getTime(), nombre: "", precio: "", estaDisponible: true };
        setMesas(prev => [...prev, nuevaMesa]);
        setErroresMesas(prev => [...prev, { nombre: "", precio: "", estaDisponible: "" }]);
        setNewMesas(prev => [...prev, nuevaMesa]);
    };

    const eliminarMesa = (index) => {
        const mesa = mesas[index];
        const updatedMesas = mesas.filter((_, idx) => idx !== index);
        setMesas(updatedMesas);

        if (mesa.id.startsWith("new-")) {
            setNewMesas(newMesas.filter(m => m.id !== mesa.id));
        } else {
            setDeletedMesas([...deletedMesas, mesa]);
            setModifiedMesas(modifiedMesas.filter(m => m.id !== mesa.id));
        }

        const updatedErrors = erroresMesas.filter((_, idx) => idx !== index);
        setErroresMesas(updatedErrors);
    };

    const handleImagenMesasChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (file.size > 1.5 * 1024 * 1024) {
                Swal.fire('Error', 'El archivo no debe exceder 1.5 MB.', 'error');
            } else {
                setImagenMesas(file);
            }
        }
    };

    const canSubmit = () => {
        const noErrors = erroresMesas.every(err => !Object.values(err).some(v => v));
        const noDescripcionError = errorMesaDescripcion === null;
        const allFieldsValid = mesas.every(mesa => validarCampo(mesa.nombre, 'nombre') && validarCampo(mesa.precio, 'precio'));
        const hasValidChanges = newMesas.length > 0 || modifiedMesas.length > 0 || deletedMesas.length > 0 || imagenMesas || descripcionMesas;
        return noErrors && allFieldsValid && hasValidChanges && noDescripcionError;
    };

    const handleSubmit = async () => {
        if (!canSubmit()) {
            Swal.fire('Error', 'Por favor, corrija los errores en los formularios antes de enviar.', 'error');
            return;
        }
        setIsLoading(true);
        try {
            const formData = new FormData();
            if (imagenMesas) {
                formData.append("imagenMesas", imagenMesas);
            }
            const mesaUpdates = {
                descripcionMesas,
                newMesas,
                modifiedMesas,
                deletedMesas
            };
            formData.append("mesaUpdates", new Blob([JSON.stringify(mesaUpdates)], { type: "application/json" }));

            const response = await axios.post(`${variables.API_BASE_URL}/api/tdt/evento/${eventoId}/actualizar-mesas-info`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                Swal.fire('Éxito', 'La información de las mesas ha sido actualizada correctamente.', 'success');
                if (imagenMesas) {
                    setUrlImagenMesas(URL.createObjectURL(imagenMesas));
                    setImagenMesas(null);
                }
                setNewMesas([]);
                setModifiedMesas([]);
                setDeletedMesas([]);
                fetchMesas();
            } else {
                Swal.fire('Error', 'No se pudo actualizar la información de las mesas.', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'No se pudo actualizar la información de las mesas.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.body}>
            <h1 className={styles.titulo}>Gestionar mesas a la venta</h1>
            <div className={styles.fondoSub}>
                <p>
                    Desde acá podés agregar imagen del <b>mapa de las mesas</b>, <b>insertar descripción,</b> <b>agregar mesas</b>, <b>editarlas</b> o <b>eliminarlas.</b>
                </p>
            </div>

            <button type="button" onClick={toggleExampleModal} className={styles.exampleButton}>
                Ver ejemplo
            </button>

            <div className={styles.contenedor}>
                <label className={styles.label}>Imagen del mapa de las mesas</label>
                <div className={styles.imagenActual}>
                    {urlImagenMesas ? (
                        <div className={styles.contImgActual}>
                            <span>Imagen actual de las mesas:</span>
                            <img style={{ maxWidth: "600px", margin: "0 auto", width: "90%" }} src={urlImagenMesas} alt="Imagen actual de las mesas" />
                            <p>¿Quieres reemplazar la imagen?</p>
                        </div>
                    ) : (
                        <p className={styles.instructivoImagen}>Introduce una imagen que muestre dónde estarán ubicadas las mesas con su numeración.</p>
                    )}
                </div>
                <input type="file" onChange={handleImagenMesasChange} />
                <label className={styles.label}>Descripción de las mesas</label>
                <textarea
                    name="descripcionMesas"
                    placeholder="Acá podes explicar qué incluyen las mesas, por ejemplo."
                    value={descripcionMesas}
                    maxLength={255}
                    onChange={handleDescripcionChange}
                    className={erroresMesas.descripcionMesas ? styles.borderInvalid : styles.borderValid}
                />
                {errorMesaDescripcion && <p className={styles.error}>{errorMesaDescripcion}</p>}
            </div>

            {cargandoMesas ?
                <div style={{ width: "90%" }}>
                    <MesaSkeleton />
                    <MesaSkeleton />
                </div>
                :
                <div className={styles.mesas}>
                    <label className={styles.label}>Lista de mesas</label>
                    {mesas.map((mesa, index) => {
                        const mesaVendida = mesa.cantidadVendida > 0 && !mesa.estaDisponible;

                        return (
                            <div className={styles.mesa} key={index}>
                                <label>Titulo de la mesa:</label>
                                <input
                                    type="text"
                                    placeholder='Ejemplo: "1A"'
                                    value={mesa.nombre}
                                    maxLength={30}
                                    onChange={(e) => handleInputChange(index, e.target.value, 'nombre')}
                                    className={erroresMesas[index]?.nombre ? styles.borderInvalid : styles.borderValid}
                                    disabled={mesaVendida}
                                />
                                {erroresMesas[index]?.nombre && <p className={styles.error}>{erroresMesas[index].nombre}</p>}

                                <label>Precio:</label>
                                <input
                                    type="number"
                                    placeholder="Ejemplo: 4000"
                                    value={mesa.precio}
                                    onChange={(e) => handleInputChange(index, e.target.value, 'precio')}
                                    className={erroresMesas[index]?.precio ? styles.borderInvalid : styles.borderValid}
                                    disabled={mesaVendida}
                                />
                                {erroresMesas[index]?.precio && <p className={styles.error}>{erroresMesas[index].precio}</p>}

                                <label>Disponible:</label>
                                <div className={styles.checkboxApple}>
                                    <input
                                        className={styles.yep}
                                        id={`checkApple-${index}`}
                                        type="checkbox"
                                        checked={mesa.estaDisponible}
                                        onChange={() => handleDisponibilidadChange(index)}
                                        disabled={mesaVendida}
                                    />
                                    <label className={styles.labelToggle} htmlFor={`checkApple-${index}`}></label>
                                </div>

                                {mesaVendida && (
                                    <p style={{ color: "red", border:"solid 1px red", borderRadius:"10px", padding:"4px", textAlign: "center", fontSize: "12px", marginBottom: "16px" }} className={styles.mensajeVendida}>
                                        Esta mesa ya fue vendida y no se puede modificar o eliminar.
                                    </p>
                                )}
                                {!mesaVendida && (
                                    <button className={styles.eliminarBtn} type="button" onClick={() => eliminarMesa(index)}>
                                        Eliminar Mesa <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                )}
                            </div>
                        );
                    })}
                    <button className={styles.agregarBtn} type="button" onClick={agregarMesa}>
                        Agregar Mesa <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            }

            <div className={styles.contBtnGuardar}>
                <SubmitButton
                    isDisabled={!canSubmit() || isLoading}
                    color="#252525"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                >
                    Guardar <FontAwesomeIcon icon={faFloppyDisk} />
                </SubmitButton>
            </div>

            <ExampleModal isOpen={isExampleModalOpen} onClose={toggleExampleModal} />
        </div>
    );
};

export default GestionMesas;